import React, { useEffect, useState } from 'react'
import { getAllUserProjects } from '../../../firebase/firestore';
import { useApp } from '../../../contexts/AppContext';
import { useAuth } from '../../../contexts/AuthContext';
import { uuidv4 } from '@firebase/util';
import { Link } from 'react-router-dom';
import routes from '../../../constants/Routes';

import DashboardProjectCard from '../dashboard-project-card';

const DashboardProjects = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { projects, setProjects, projectsDirty, setProjectsDirty } = useApp()
  const { currentUser } = useAuth()

  useEffect(() => {
    getProjects()
  }, [projectsDirty])

  const getProjects = async () => {

    if (projectsDirty) {
      const data = await getAllUserProjects(currentUser)
      setProjects(data)
      setProjectsDirty(false)
    }
    setIsLoading(false)
  }

  return (
    <>
      {isLoading ?
        <>
          {/* <div style={{ display: 'flex', gap: '15px', justifyContent: 'flex-start' }}>
            <CardSkeleton style={{ marginRight: '5px', width: '200px' }} />
            <CardSkeleton style={{ width: '200px' }} />
          </div> */}
        </>
        :
        <section className="px-4 py-4 sm:px-8">
          <h1 className="text-gray-800 font-bold text-2xl md:text-3xl">Your Avatars</h1>
          {projects.length === 0
            ?
            <h2 className="text-gray-800 font-bold text-xl md:text-2xl">
              Don't have any avatars? <Link to={routes.DASHBOARD}>Create a new avatar</Link>
            </h2>
            : null}
          <div className="grid gap-x-8 gap-y-8 sm:grid-cols-2 lg:grid-cols-3">
            {projects.map((p) => {
              return <DashboardProjectCard key={uuidv4()} project={p} setProjectsDirty={setProjectsDirty}></DashboardProjectCard>
            })}
          </div>
        </section>
      }
    </>
  )
}

export default DashboardProjects