import React, { useState, useRef } from 'react'
import { PlusIcon, MinusIcon } from '@heroicons/react/24/solid'

const faqsList = [
    {
        question: 'What is a custom 2D avatar?',
        answer: 'A custom 2D avatar is a personalized digital character that you can create by selecting various building blocks like characters, hairs, clothes, etc.'
    },
    {
        question: 'What is the purpose of this website?',
        answer: 'This website provides a platform for users to create and customize their own unique 2D avatars for personal or commercial use.'
    },
    {
        question: 'Is the website free to use?',
        answer: 'The website operates on a freemium model, where some building blocks are available for free, and others are available for purchase using avatar coins.'
    },
    {
        question: 'How can I purchase avatar coins?',
        answer: 'You can purchase avatar coins in bulk from the website. These coins can be used to purchase additional building blocks for customization.'
    },
    {
        question: 'How can I download my custom 2D avatar?',
        answer: 'You can download your custom 2D avatar in PNG format with or without a background.'
    },
    {
        question: 'Can I use my custom 2D avatar with other tools and AI technologies?',
        answer: 'Yes, the downloaded avatars can be used with other tools and AI technologies.'
    },
    {
        question: 'Do I need to create an account to use the website?',
        answer: 'Yes, you need to register and create an account to use the website and save your custom 2D avatar.'
    },
    {
        question: 'Is there a limit to the number of custom 2D avatars I can create?',
        answer: 'You can create as many custom 2D avatars as you like and download them.'
    },
    {
        question: 'Can I use my custom 2D avatar for commercial purposes?',
        answer: 'Yes, you can use your custom 2D avatar for commercial purposes. However, you should check the website\'s terms of use and licensing agreements for any restrictions or guidelines.'
    },
    {
        question: 'What type of building blocks are available for customization?',
        answer: 'Building blocks for customization may include characters, hairs, clothes, accessories, and more. You can purchase additional building blocks using avatar coins or avail them for free as per the website\'s freemium model.'
    },
    {
        question: 'What is the image resolution of the downloaded custom 2D avatar?',
        answer: 'The image resolution of the downloaded custom 2D avatar may vary based on the website\'s specifications. It is advisable to check the website\'s guidelines and download options for more information.'
    },
    {
        question: 'Is there a customer support team available for assistance?',
        answer: 'Yes, the website has a dedicated customer support team to assist you with any questions or concerns you may have while using the website.'
    }
];

const FaqsCard = ({ faqsList }) => {

    const answerElRef = useRef()
    const [state, setState] = useState(false)
    const [answerH, setAnswerH] = useState('0px')

    const handleOpenAnswer = () => {
        const answerElH = answerElRef.current.childNodes[0].offsetHeight
        setState(!state)
        setAnswerH(`${answerElH + 20}px`)
    }

    return (
        <div 
            className="space-y-3 mt-4 overflow-hidden border-b"
            onClick={handleOpenAnswer}>
            <h4 className="cursor-pointer pb-4 flex items-center justify-between text-lg text-gray-700 font-medium">
                {faqsList.question}
                {
                    state ? (
                        <MinusIcon className="h-5 w-5" />
                    ) : (
                        <PlusIcon className="h-5 w-5" />
                    )
                }
            </h4>
            <div
                ref={answerElRef} className="duration-300"
                style={state ? {height: answerH } : {height: '0px'}}
            >
                <div>
                    <p className="text-gray-500">
                        {faqsList.answer}
                    </p>
                </div>
            </div>
        </div>
    )
}

const FaqList = () => {

    return (
        <section className="leading-relaxed max-w-screen-xl mt-12 mx-auto px-4 md:px-8">
            <div className="space-y-3 text-center">
                <h1 className="text-3xl text-gray-800 font-extrabold">
                    Frequently Asked Questions
                </h1>
            </div>
            <div className="mt-14 max-w-2xl mx-auto">
                {
                    faqsList.map((item, idx) => (
                        <FaqsCard
                            key={idx}
                            faqsList={item}
                        />
                    ))
                }
            </div>
        </section>
    )
}

export default FaqList