import React, { useState } from 'react'
import SwitchButton from '../../../switch-button';
const DownloadAvatarCard = ({ handleDownload, avatarUpdating }) => {
    const radios = {
        'withBackground': {
            label: 'Background',
            value: '1'
        },
        'withoutBackground': {
            label: 'No Background',
            value: '2'
        }
    }

    const [selection, setSelection] = useState(radios.withBackground.value)

    const handleChange = (value) => {
        setSelection(value)
    }

    return (
        <div className='flex flex-col gap-y-3' >
            <div className='flex w-full place-content-end'>
                <SwitchButton checked={selection === radios.withBackground.value}
                    enabled={!avatarUpdating}
                    label={radios.withBackground.label}
                    labelClasses={'text-sm'}
                    onChange={() => { handleChange(selection === radios.withBackground.value ? '' : radios.withBackground.value) }} />
            </div>

            <div className='flex w-full place-content-end'>
                <SwitchButton checked={selection === radios.withoutBackground.value}
                    enabled={!avatarUpdating}
                    label={radios.withoutBackground.label}
                    labelClasses={'text-sm'}
                    onChange={() => { handleChange(selection === radios.withoutBackground.value ? '' : radios.withoutBackground.value) }} />
            </div>
            {/* <ToggleButtonGroup name="download-options" type="radio" value={selection} onChange={handleChange}>
                <ToggleButton size='sm' variant="outline-primary" id="tbg-btn-1" value={radios['withBackground'].value}>
                    {radios['withBackground'].label}
                </ToggleButton>
                <ToggleButton size='sm' variant="outline-primary" id="tbg-btn-2" value={radios['withoutBackground'].value}>
                    {radios['withoutBackground'].label}
                </ToggleButton>
            </ToggleButtonGroup> */}

            {/* Download Background Button */}
            <div className='mx-1'>
                <button
                    className={`${avatarUpdating
                        ? 'bg-indigo-300 cursor-not-allowed'
                        : 'bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-600'} w-full my-2 py-1 text-sm rounded-md`}
                    disabled={avatarUpdating}
                    onClick={(e) => { handleDownload(e, { HasBackground: selection === '1' }) }}>
                    Download Avatar
                </button>
            </div>
        </div >

    )
}

export default DownloadAvatarCard