// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from 'firebase/auth'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBxagqqlxMM271x5xcY9mmYhBYCsdanbZc",
  authDomain: "avatar-maker-prod.firebaseapp.com",
  projectId: "avatar-maker-prod",
  storageBucket: "avatar-maker-prod.appspot.com",
  messagingSenderId: "491383295226",
  appId: "1:491383295226:web:9cecebf76827d38d11cd43",
  measurementId: "G-V3H6T4MWL7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth(app)

export { app, auth }