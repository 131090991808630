import React from 'react'

const AlertDanger = ({ heading, subheading }) => {
    return (
        <div className="w-full mx-auto">
            <div className="flex justify-between p-2 rounded-md bg-red-50 border border-red-300">
                <div className="flex gap-3">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <div className="self-center">
                        {heading && heading.length > 0 &&
                            <span className="text-red-600 font-medium">
                                {heading}
                            </span>
                        }

                        <div className="text-red-600">
                            <p className="mt-2 sm:text-sm">
                                {subheading}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const AlertSuccess = ({ heading, subheading }) => {
    return (
        <div className="w-full mx-auto">
            <div className="flex justify-between p-2 rounded-md  bg-green-50 border border-green-300">
                <div className="flex gap-3">
                    <div>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <div className="self-center">
                        {heading && heading.length > 0 &&
                            <span className="text-green-600 font-medium">
                                {heading}
                            </span>
                        }

                        <div className="text-green-600">
                            <p className="mt-2 sm:text-sm">
                                {subheading}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const alertMap = {
    'danger': AlertDanger,
    'success': AlertSuccess
}

const Alert = ({ type, heading, subheading }) => {
    const Component = alertMap[type]
    if (!Component) {
        return null
    }
    return (
        <Component heading={heading} subheading={subheading}>index</Component>
    )
}



export default Alert