import { app } from "./firebase";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  getDocs,
  collection,
  deleteDoc,
} from "firebase/firestore";
import { getNewProject } from "./avatar-project";
import { uuidv4 } from "@firebase/util";
import { getCurrentDateTime } from "../helper/helper";
import { deepCopy } from "../helper/helper";

const db = getFirestore(app);

export const addNewUser = async (user) => {
  try {
    const userData = {
      email: user.email,
    };

    return setDoc(doc(db, "users", user.uid), userData);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

export const getAllProjectTypes = async () => {
  let docs = [];
  try {
    const projectsSnap = await getDocs(collection(db, "projectsTypes"));
    projectsSnap.forEach((doc) => {
      docs.push(doc.data());
    });
    return docs;
  } catch (e) {
    console.error("Error fetching project types: ", e);
  }
};

export const getAllUserProjects = async (user) => {
  let docs = [];
  try {
    const projectsSnap = await getDocs(
      collection(db, `users/${user.uid}/projects`)
    );
    projectsSnap.forEach((doc) => {
      docs.push({ ...doc.data(), id: doc.id });
    });
    return docs;
  } catch (e) {
    console.error("Error fetching projects: ", e);
  }
};

export const addNewProject = async (uid, project) => {
  try {
    const newProject = getNewProject(project);
    const parentRef = doc(db, "users", uid);
    const projectId = uuidv4();
    await setDoc(doc(parentRef, "projects", projectId), newProject);
    return projectId;
  } catch (e) {}
};

export const makeProjectCopy = async (uid, existingProject) => {
  try {
    const currentDateTime = getCurrentDateTime();
    const newProject = deepCopy(existingProject);
    newProject.name = `${newProject.name} - copy`;
    newProject.createDate = newProject.lastModified = currentDateTime;

    const parentRef = doc(db, "users", uid);
    return setDoc(doc(parentRef, "projects", uuidv4()), newProject);
  } catch (e) {}
};

export const removeProject = async (uid, projectId) => {
  return deleteDoc(doc(db, "users", uid, "projects", projectId));
};

export const getProjectById = async (uid, projectId) => {
  const projectSnap = await getDoc(
    doc(db, "users", uid, "projects", projectId)
  );

  const data = projectSnap.data();
  if (data) {
    return { ...projectSnap.data(), id: projectSnap.id };
  } else {
    return undefined;
  }
};

export const updateProjectName = async (uid, projectId, newName) => {
  try {
    const projectRef = doc(db, "users", uid, "projects", projectId);
    return setDoc(
      projectRef,
      {
        Name: newName,
      },
      { merge: true }
    );
  } catch (e) {}
};

export const ProjectDataKey = {
  CHARACTER: "CHARACTER",
  EYES: "EYES",
  CLOTHING: "CLOTHING",
  HAIR: "HAIR",
  LIPS: "LIPS",
  MAKEUP: "MAKEUP",
  POSE: "POSE",
  FACE: "FACE",
  SKIN: "SKIN",
  ACCESSORY_EYEWEAR: "ACCESSORY_EYEWEAR",
  BEARD: "BEARD",
  BACKGROUND: "BACKGROUND",
  EXPRESSION: "EXPRESSION",
};

export const updateProjectData = (uid, projectId, dataKey, data) => {
  try {
    let obj;
    switch (dataKey) {
      case ProjectDataKey.EYES:
        obj = {
          Eyes: data,
        };
        break;

      case ProjectDataKey.CHARACTER:
        obj = {
          Character: data,
        };
        break;

      case ProjectDataKey.POSE:
        obj = {
          Pose: data,
        };
        break;

      case ProjectDataKey.SKIN:
        obj = {
          Skin: data,
        };
        break;

      case ProjectDataKey.EXPRESSION:
        obj = {
          Expression: data,
        };
        break;

      case ProjectDataKey.CLOTHING:
        obj = {
          Clothing: data,
        };
        break;

      case ProjectDataKey.HAIR:
        obj = {
          Hair: data,
        };
        break;

      case ProjectDataKey.LIPS:
        obj = {
          Lips: data,
        };
        break;

      case ProjectDataKey.MAKEUP:
        obj = {
          Makeup: data,
        };
        break;

      case ProjectDataKey.ACCESSORY_EYEWEAR:
        obj = {
          Accessory_Eyewear: data,
        };
        break;

      case ProjectDataKey.BEARD:
        obj = {
          Beard: data,
        };
        break;

      case ProjectDataKey.BACKGROUND:
        obj = {
          Background: data,
        };
        break;

      default:
        // do nothing
        break;
    }

    if (obj) {
      obj = { ...obj, LastModified: getCurrentDateTime() };

      const projectRef = doc(db, "users", uid, "projects", projectId);

      return setDoc(projectRef, obj, { merge: true });
    } else {
      throw new Error("No key to match!");
    }
  } catch (e) {}
};
