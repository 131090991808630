import React, { useState, useEffect, useRef, Fragment } from 'react'
import SwitchButton from '../../switch-button';
import { updateProjectData, ProjectDataKey } from '../../../firebase/firestore';
import { useApp } from '../../../contexts/AppContext';
import AvatarManager from '../../../avatar/AvatarManager';
import { getCurrentDateTime, randomizeProject, deepCopy } from '../../../helper/helper';
import Sidebar from './sidebar';


import AvatarItemCard from './avatar-item-card-wrapper';
import AvatarBlockCard from './avatar-block-card';
import CharacterCardBody from './character-card-body';
import PoseCardBody from './pose-card-body';
import SkinCardBody from './skin-card-body';
import ExpressionCardBody from './expression-card-body';
import ClothingCardBody from './clothing-card-body';
import HairCardBody from './hair-card-body';
import BeardCardBody from './beard-card-body';
import AccessoryEyewearCardBody from './accessory-eyewear-card-body';
import LipsCardBody from './lips-card-body';
import EyesCardBody from './eyes-card-body';
import CardBackgroundBody from './card-background-body';
import DownloadAvatarCard from './avatar-download-card-body';
// import MakeupCardBody from './makeup-card-body';

import './style.css'

const ProjectCard = ({ uid, project, setProject, projectType }) => {

    const labelMap = {
        'character': 'Character',
        'clothing': 'Clothing',
        'hair': 'Hair',
        'beard': 'Beard',
        'eyes': 'Eyes',
        'lips': 'Lips',
        'makeup': 'Makeup',
        'expression': 'Expression',
        'pose': 'Pose',
        'skin': 'Skin',
        'face': 'Face',
        'accessory_eyewear': 'Eyewear',
        'background': 'Background',
        'download': 'Download',
    }

    const [selectedCard, setSelectedCard] = useState('')
    const [showBlockList, setShowBlockList] = useState(false)

    const canvasRef = useRef(null, (canvas) => {
        if (canvas) {
            canvasRef.current = canvas;
        }
    })
    const { projects, setProjects, avatarUpdating, setAvatarUpdating } = useApp()

    useEffect(() => {

        if (canvasRef && canvasRef.current && project && projectType) {
            loadAvatarCanvas(project, canvasRef.current)
        }

    }, [canvasRef, project, projectType])

    async function loadAvatarCanvas(project, canvas) {
        try {
            setAvatarUpdating(true)
            // load all the avatar layers   
            await AvatarManager.loadAvatarCanvas(project, projectType, canvas)
            setAvatarUpdating(false)
        }
        catch (e) {

        }
    }

    const handleSelectedEyesChange = (e, type) => {
        e.preventDefault();

        setAvatarUpdating(true)
        updateProjectData(uid, project.id, ProjectDataKey.EYES, type)
            .then(() => {
                const updatedProject = { ...project, Eyes: type }
                projectUpdated(updatedProject)
            })
    };

    const handleSelectedCharacterChange = (e, type) => {
        e.preventDefault();

        setAvatarUpdating(true)
        updateProjectData(uid, project.id, ProjectDataKey.CHARACTER, type)
            .then(() => {
                const updatedProject = { ...project, Character: type }
                projectUpdated(updatedProject)
            })
    };

    const handleSelectedPoseChange = (e, type) => {
        e.preventDefault();

        setAvatarUpdating(true)
        updateProjectData(uid, project.id, ProjectDataKey.POSE, type)
            .then(() => {
                const updatedProject = { ...project, Pose: type }
                projectUpdated(updatedProject)
            })
    };

    const handleSelectedSkinChange = (e, type) => {
        e.preventDefault();

        setAvatarUpdating(true)
        updateProjectData(uid, project.id, ProjectDataKey.SKIN, type)
            .then(() => {
                const updatedProject = { ...project, Skin: type }
                projectUpdated(updatedProject)
            })
    };

    const handleSelectedExpressionChange = (e, type) => {
        e.preventDefault();

        setAvatarUpdating(true)
        updateProjectData(uid, project.id, ProjectDataKey.EXPRESSION, type)
            .then(() => {
                const updatedProject = { ...project, Expression: type }
                projectUpdated(updatedProject)
            })
    };

    const handleSelectedClothingChange = (e, type) => {
        e.preventDefault();

        setAvatarUpdating(true)
        const clothingObj = { ...project.Clothing, Selection: type }
        updateProjectData(uid, project.id, ProjectDataKey.CLOTHING, clothingObj)
            .then(() => {
                const updatedProject = { ...project, Clothing: clothingObj }
                projectUpdated(updatedProject)
            })
    };

    const handleSelectedHairChange = (isSelected, type) => {
        if (!isSelected) {
            type = ''
        }

        setAvatarUpdating(true)
        const hairObj = { ...project.Hair, Enabled: isSelected, Selection: type }
        updateProjectData(uid, project.id, ProjectDataKey.HAIR, hairObj)
            .then(() => {
                const updatedProject = { ...project, Hair: hairObj }
                projectUpdated(updatedProject)
            })
    };

    const handleSelectedBeardChange = (isSelected, type) => {
        if (!isSelected) {
            type = ''
        }

        setAvatarUpdating(true)
        const beardObj = { ...project.Beard, Enabled: isSelected, Selection: type }
        updateProjectData(uid, project.id, ProjectDataKey.BEARD, beardObj)
            .then(() => {
                const updatedProject = { ...project, Beard: beardObj }
                projectUpdated(updatedProject)
            })
    };

    const handleSelectedAccessoryEyewearChange = (isSelected, type) => {
        if (!isSelected) {
            type = ''
        }

        setAvatarUpdating(true)
        const obj = { ...project.Accessory_Eyewear, Enabled: isSelected, Selection: type }
        updateProjectData(uid, project.id, ProjectDataKey.ACCESSORY_EYEWEAR, obj)
            .then(() => {
                const updatedProject = { ...project, Accessory_Eyewear: obj }
                projectUpdated(updatedProject)
            })
    };

    const handleSelectedLipsChange = (isSelected, type) => {
        if (!isSelected) {
            type = ''
        }

        setAvatarUpdating(true)
        const lipsObj = { ...project.Lips, Enabled: isSelected, Selection: type }
        updateProjectData(uid, project.id, ProjectDataKey.LIPS, lipsObj)
            .then(() => {
                const updatedProject = { ...project, Lips: lipsObj }
                projectUpdated(updatedProject)
            })
    };

    // const handleSelectedMakeupChange = (e, isSelected, type) => {
    //     e.preventDefault();

    //     if (!isSelected) {
    //         type = ''
    //     }

    //     setAvatarUpdating(true)
    //     const makeupObj = { HasMakeup: isSelected, SelectedType: type }
    //     updateProjectData(uid, project.id, ProjectDataKey.MAKEUP, makeupObj)
    //         .then(() => {
    //             const updatedProject = { ...project, Makeup: makeupObj }
    //             projectUpdated(updatedProject)
    //         })
    // };

    const handleBackgroundChange = (background) => {
        setAvatarUpdating(true)

        updateProjectData(uid, project.id, ProjectDataKey.BACKGROUND, background)
            .then(() => {
                const updatedProject = { ...project, Background: background }
                projectUpdated(updatedProject)
            })
    };

    const projectUpdated = (updatedProject) => {
        setProject(() => updatedProject)
        const newProjects = projects.filter((p) => { return p.id !== updatedProject.id })
        setProjects(() => [...newProjects, { ...updatedProject, LastModified: getCurrentDateTime() }])
    }

    const blockCardClick = (e, cardType) => {
        e.preventDefault()

        if (cardType === selectedCard) {
            setSelectedCard(currentValue => '')
            setShowBlockList(false)
        } else {
            setSelectedCard(currentValue => cardType)
            setShowBlockList(true)
        }

    }

    const handleDownload = (e, downloadParams) => {
        const { HasBackground } = downloadParams
        const tempProject = deepCopy(project)
        if (!HasBackground) {
            tempProject.Background.HasBackground = false
        }
        const downloadCanvas = document.createElement('canvas')
        downloadCanvas.width = canvasRef.current.width
        downloadCanvas.height = canvasRef.current.height
        downloadFromCanvas(tempProject, downloadCanvas)

        async function downloadFromCanvas(p, c) {
            const response = await AvatarManager.loadAvatarCanvas(p, projectType, c)
            if (response.success) {
                var imageData = c.toDataURL("image/png").replace("image/png", "image/octet-stream");

                // Create a download link for the image
                var downloadLink = document.createElement("a");
                downloadLink.href = imageData;
                downloadLink.download = `${p.Name}.png`;

                // Trigger the download
                downloadLink.click();
            }
        }

    }

    const randomizeAvatar = (e) => {
        e.preventDefault()
        handleBulkUpdate(randomizeProject(project, projectType))

    }

    const handleBulkUpdate = (projectObj) => {
        const { clothingObj, hairObj, lipsObj, eyesObj, expressionObj, poseObj, skinObj } = projectObj
        const promises = []

        setAvatarUpdating(true)
        promises.push(updateProjectData(uid, project.id, ProjectDataKey.CLOTHING, clothingObj))
        promises.push(updateProjectData(uid, project.id, ProjectDataKey.HAIR, hairObj))
        promises.push(updateProjectData(uid, project.id, ProjectDataKey.LIPS, lipsObj))
        promises.push(updateProjectData(uid, project.id, ProjectDataKey.EYES, eyesObj))
        promises.push(updateProjectData(uid, project.id, ProjectDataKey.EXPRESSION, expressionObj))
        promises.push(updateProjectData(uid, project.id, ProjectDataKey.POSE, poseObj))
        promises.push(updateProjectData(uid, project.id, ProjectDataKey.SKIN, skinObj))

        Promise.all(promises).then(() => {
            const updatedProject = { ...project, Clothing: clothingObj, Hair: hairObj, Lips: lipsObj, Eyes: eyesObj, Expression: expressionObj, Pose: poseObj, Skin: skinObj }
            projectUpdated(updatedProject)
        })
    }

    const mainList = project && projectType && (<Fragment>
        {projectType.Characters.Available && <AvatarBlockCard labelMap={labelMap} type={'character'} isSelected={selectedCard === 'character'} clickHandler={blockCardClick}></AvatarBlockCard>}
        {projectType.Pose.Available && <AvatarBlockCard labelMap={labelMap} type={'pose'} isSelected={selectedCard === 'pose'} clickHandler={blockCardClick}></AvatarBlockCard>}
        {projectType.Skin.Available && <AvatarBlockCard labelMap={labelMap} type={'skin'} isSelected={selectedCard === 'skin'} clickHandler={blockCardClick}></AvatarBlockCard>}
        {projectType.Expression.Available && <AvatarBlockCard labelMap={labelMap} type={'expression'} isSelected={selectedCard === 'expression'} clickHandler={blockCardClick}></AvatarBlockCard>}
        {projectType.Clothing.Available && <AvatarBlockCard labelMap={labelMap} type={'clothing'} isSelected={selectedCard === 'clothing'} clickHandler={blockCardClick}></AvatarBlockCard>}
        {projectType.Hair.Available && <AvatarBlockCard labelMap={labelMap} type={'hair'} isSelected={selectedCard === 'hair'} clickHandler={blockCardClick}></AvatarBlockCard>}
        {projectType.Beard.Available && <AvatarBlockCard labelMap={labelMap} type={'beard'} isSelected={selectedCard === 'beard'} clickHandler={blockCardClick}></AvatarBlockCard>}
        {projectType.Lips.Available && <AvatarBlockCard labelMap={labelMap} type={'lips'} isSelected={selectedCard === 'lips'} clickHandler={blockCardClick}></AvatarBlockCard>}
        {projectType.Eyes.Available && <AvatarBlockCard labelMap={labelMap} type={'eyes'} isSelected={selectedCard === 'eyes'} clickHandler={blockCardClick}></AvatarBlockCard>}
        {projectType.Accessory_Eyewear.Available && <AvatarBlockCard labelMap={labelMap} type={'accessory_eyewear'} isSelected={selectedCard === 'accessory_eyewear'} clickHandler={blockCardClick}></AvatarBlockCard>}

        <AvatarBlockCard labelMap={labelMap} type={'background'} isSelected={selectedCard === 'background'} clickHandler={blockCardClick}></AvatarBlockCard>
        <AvatarBlockCard labelMap={labelMap} type={'download'} isSelected={selectedCard === 'download'} clickHandler={blockCardClick}></AvatarBlockCard>
    </Fragment>)

    const floatingList = project && projectType && (<Fragment>
        {selectedCard === 'character' &&
            <AvatarItemCard
                headerText={'Character'}
                cardBody={<CharacterCardBody
                    handleChange={handleSelectedCharacterChange}
                    characterList={projectType.Characters.Types}
                    selectedItem={project.Character}
                    avatarUpdating={avatarUpdating} />}>
            </AvatarItemCard>
        }

        {selectedCard === 'pose' &&
            <AvatarItemCard
                headerText={'Pose'}
                cardBody={<PoseCardBody
                    handleChange={handleSelectedPoseChange}
                    poseList={projectType.Pose.Types}
                    selectedItem={project.Pose}
                    avatarUpdating={avatarUpdating} />}>
            </AvatarItemCard>
        }

        {selectedCard === 'skin' &&
            <AvatarItemCard
                headerText={'Skin'}
                cardBody={<SkinCardBody
                    handleChange={handleSelectedSkinChange}
                    skinList={projectType.Skin.Types}
                    selectedItem={project.Skin}
                    avatarUpdating={avatarUpdating} />}>
            </AvatarItemCard>
        }

        {selectedCard === 'expression' &&
            <AvatarItemCard
                headerText={'Expression'}
                cardBody={<ExpressionCardBody
                    handleChange={handleSelectedExpressionChange}
                    expressionList={projectType.Expression.Types}
                    selectedItem={project.Expression}
                    avatarUpdating={avatarUpdating} />}>
            </AvatarItemCard>
        }

        {selectedCard === 'clothing' &&
            <AvatarItemCard
                headerText={'Clothing'}
                cardBody={<ClothingCardBody
                    handleChange={handleSelectedClothingChange}
                    clothingList={projectType.Clothing.Types}
                    selectedItem={project.Clothing.Selection}
                    avatarUpdating={avatarUpdating} />}>
            </AvatarItemCard>
        }

        {selectedCard === 'hair' &&
            <AvatarItemCard
                headerText={'Hair'}
                toggleButton={
                    <SwitchButton checked={project.Hair.Enabled}
                        enabled={!avatarUpdating}
                        // label={project.Hair.Enabled ? 'Remove Hair' : 'Add Hair'}
                        onChange={(e) => { handleSelectedHairChange(e, !(project.Hair.Enabled), project.Hair.Selection) }} />
                }
                cardBody={<HairCardBody
                    handleChange={handleSelectedHairChange}
                    hairList={projectType.Hair.Types}
                    selectedItem={project.Hair}
                    avatarUpdating={avatarUpdating} />}>
            </AvatarItemCard>
        }

        {selectedCard === 'beard' &&
            <AvatarItemCard
                headerText={'Beard'}
                toggleButton={
                    <SwitchButton checked={project.Beard.Enabled}
                        enabled={!avatarUpdating}
                        // label={project.Beard.Enabled ? 'Remove Beard' : 'Add Beard'}
                        onChange={(e) => { handleSelectedBeardChange(e, !(project.Beard.Enabled), project.Beard.Selection) }} />
                }
                cardBody={<BeardCardBody
                    handleChange={handleSelectedBeardChange}
                    beardList={projectType.Beard.Types}
                    selectedItem={project.Beard}
                    avatarUpdating={avatarUpdating} />}>
            </AvatarItemCard>
        }

        {selectedCard === 'lips' &&
            <AvatarItemCard
                headerText={'Lips'}
                toggleButton={
                    <SwitchButton checked={project.Lips.Enabled}
                        enabled={!avatarUpdating}
                        // label={project.Lips.Enabled ? 'Remove Lip Color' : 'Add Lip Color'}
                        onChange={(e) => { handleSelectedLipsChange(e, !(project.Lips.Enabled), project.Lips.Selection) }} />
                }
                cardBody={<LipsCardBody
                    handleChange={handleSelectedLipsChange}
                    lipList={projectType.Lips.Types}
                    selectedItem={project.Lips}
                    avatarUpdating={avatarUpdating} />}>
            </AvatarItemCard>
        }

        {selectedCard === 'eyes' &&
            <AvatarItemCard
                headerText={'Eyes'}
                cardBody={<EyesCardBody
                    handleChange={handleSelectedEyesChange}
                    eyesList={projectType.Eyes.Types}
                    selectedItem={project.Eyes}
                    avatarUpdating={avatarUpdating} />}>
            </AvatarItemCard>
        }

        {selectedCard === 'accessory_eyewear' &&
            <AvatarItemCard
                headerText={'Eyewear'}
                toggleButton={
                    <SwitchButton checked={project.Accessory_Eyewear.Enabled}
                        enabled={!avatarUpdating}
                        // label={project.Accessory_Eyewear.Enabled ? 'Remove Eyewear' : 'Add Eyewear'}
                        onChange={(e) => { handleSelectedAccessoryEyewearChange(e, !(project.Accessory_Eyewear.Enabled), project.Accessory_Eyewear.Selection) }} />
                }
                cardBody={<AccessoryEyewearCardBody
                    handleChange={handleSelectedAccessoryEyewearChange}
                    accessoryEyewearList={projectType.Accessory_Eyewear.Types}
                    selectedItem={project.Accessory_Eyewear}
                    avatarUpdating={avatarUpdating} />}>
            </AvatarItemCard>
        }

        {selectedCard === 'background' &&
            <AvatarItemCard
                headerText={'Background'}
                cardBody={<CardBackgroundBody
                    handleChange={handleBackgroundChange}
                    background={project.Background}
                    backgroundTypes={projectType.Backgrounds.Types}
                    avatarUpdating={avatarUpdating} />}>
            </AvatarItemCard>
        }

        {selectedCard === 'download' &&
            <AvatarItemCard
                cardBody={<DownloadAvatarCard
                    handleDownload={handleDownload}
                    avatarUpdating={avatarUpdating} />}>
            </AvatarItemCard>
        }
    </Fragment>)

    const buttonClasses = `${avatarUpdating ? 'bg-indigo-300 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-600 duration-150'} px-4 py-2 font-medium rounded-md text-white`

    return (
        <>
            {project && projectType &&
                <>
                    <div className="">
                        <Sidebar mainList={mainList} floatingList={floatingList} floatingListVisible={showBlockList} />
                    </div>
                    <section className='flex mt-4 md:mt-12'>
                        <div className="m-auto border rounded-lg">
                            <div className="flex items-start justify-between p-4">
                                <canvas ref={canvasRef} width='512' height='512' className='w-64 h-64 md:w-[512px] md:h-[512px] rounded-xl'></canvas>
                            </div>
                            <div className="py-2 px-4 border-t text-center">
                                <button onClick={(e) => { randomizeAvatar(e) }} disabled={avatarUpdating} className={buttonClasses}>
                                    RANDOMIZE
                                </button>
                            </div>
                        </div>
                    </section>
                </>}
        </>
    )
}

export default ProjectCard