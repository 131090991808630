import React, {memo} from 'react'

const AvatarBlockCard = ({ labelMap, type, isSelected, clickHandler }) => {
    return (
        <div className="px-2">
            <button className={`${isSelected ? 'bg-amber-300  text-black' : 'bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-600'} w-full py-1 mb-2 text-sm font-medium rounded-sm duration-150`}
                onClick={(e) => { clickHandler(e, type) }}>
                {labelMap[type]}
            </button>
        </div>

    )
}

export default memo(AvatarBlockCard)