import React, { useState, memo } from 'react'
import SwitchButton from '../../../switch-button';
const CardBackgroundBody = ({ handleChange, background, backgroundTypes, avatarUpdating }) => {
    const radios = {
        'gradientBackground': {
            label: 'Gradient',
            value: 'g'
        },
        'customBackground': {
            label: 'Custom',
            value: 'c'
        }
    }

    const [selection, setSelection] = useState(background.Type)

    function handleBackgroundChange(background) {
        setSelection(background.Type)
        handleChange(background)
    }

    const handleRadioChange = (value) => {
        setSelection(value)
        handleChange({ ...background, Type: value, Selection: '' })
    }

    return (
        <div className='flex flex-col gap-y-3' >
            {/* Add/Remove Background */}
            <div className='flex w-full place-content-center'>
                <SwitchButton checked={background.HasBackground}
                    enabled={!avatarUpdating}
                    // label={project.Hair.Enabled ? 'Remove Background' : 'Add Background'}
                    onChange={() => { handleBackgroundChange({ HasBackground: !background.HasBackground, Type: '', Selection: '' }) }} />
            </div>

            {background.HasBackground &&
                <>
                    <div className='flex w-full place-content-end'>
                        <SwitchButton checked={selection === radios.gradientBackground.value}
                            enabled={!avatarUpdating}
                            label={radios.gradientBackground.label}
                            onChange={() => { handleRadioChange(selection === radios.gradientBackground.value ? '' : radios.gradientBackground.value) }} />
                    </div>

                    {/* <div className='flex w-full place-content-end'>
                        <SwitchButton checked={selection === radios.customBackground.value}
                            enabled={!avatarUpdating}
                            label={radios.customBackground.label}
                            onChange={() => { handleRadioChange(selection === radios.customBackground.value ? '' : radios.customBackground.value) }} />
                    </div> */}

                    {/* {background.Type === 'c' && Object.keys(backgroundTypes).sort().map(type =>
                        <div key={type} className='border-[1px] rounded-xl'>
                            <img className="mx-auto d-block w-32 h-32"
                                src={backgroundTypes[type].ThumbnailUrl}
                                alt={backgroundTypes[type].Caption} />
                            <div className='mx-1'>
                                <button
                                    className={`${avatarUpdating || (backgroundTypes[type].Id === background.Selection)
                                        ? 'bg-indigo-300 cursor-not-allowed'
                                        : 'bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-600'} w-full my-2 py-1 text-sm rounded-md`}
                                    disabled={avatarUpdating || (backgroundTypes[type].Id === background.Selection)}
                                    onClick={(e) => { handleBackgroundChange(e, { ...background, Selection: backgroundTypes[type].Id }) }}>
                                    {(backgroundTypes[type].Id === background.Selection) ? 'Applied' : 'Apply'}
                                </button>
                            </div>
                        </div>)} */}
                </>
            }
        </div >

    )
}

export default memo(CardBackgroundBody)