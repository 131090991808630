import React from 'react'
import { Navigate, Link } from 'react-router-dom'
import { useAuth } from '../../contexts/AuthContext'
import routes from '../../constants/Routes'

const Home = () => {

    const { userLoggedIn } = useAuth()

    return (
        <>
            {userLoggedIn && (<Navigate to={routes.DASHBOARD} replace={true} />)}

            <section className="w-full h-full mt-12 self-center mx-auto max-w-screen-xl pb-12 px-4 items-center lg:flex md:px-8">
                <div className="space-y-4 flex-1 sm:text-center lg:text-left">
                    <h1 className="text-gray-800 font-extrabold text-4xl text-center lg:text-left">
                        Design Your Own 2D Avatar for Games, Comics, and AI with <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#4F46E5] to-[#E114E5]">Avatar Smith!</span>
                    </h1>
                    <p>
                        Unleash your imagination and create the perfect 2D avatar for your gaming, comic, AI, and many more needs with our tool! Choose from a wide range of customizable building blocks, including characters, clothing, hairstyles, beards, and more, to create a unique avatar that reflects your personality and style. Start building your perfect avatar today and let your creativity shine!
                    </p>
                    <div className="pt-10 items-center justify-center space-y-3 sm:space-x-6 sm:space-y-0 sm:flex lg:justify-start">
                        <Link
                            className="block py-2 px-4 text-center text-white font-medium bg-indigo-600 duration-150 hover:bg-indigo-500 active:bg-indigo-700 rounded-lg shadow-lg hover:shadow-none"
                            to={routes.DASHBOARD}>
                            Get Started Now!
                        </Link>
                        <Link
                            className="block py-2 px-4 text-center text-gray-700 hover:text-gray-500 font-medium duration-150 active:bg-gray-100 border rounded-lg"
                            to={routes.ABOUT}>
                            Learn More
                        </Link>
                    </div>
                </div>
                <div className="flex-1 text-center mt-7 lg:mt-0 lg:ml-3">
                    <img alt='Avatar Smith Preview' src={process.env.PUBLIC_URL + '/images/home/home-avatar-1.png'} className="w-full rounded-2xl mx-auto sm:w-10/12  lg:w-full" />
                </div>
            </section>
        </>

    )
}

export default Home