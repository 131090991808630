import React, { useEffect, useState } from 'react'
import { addNewProject } from '../../../firebase/firestore';
import { uuidv4 } from '@firebase/util';
import { useApp } from '../../../contexts/AppContext';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import routes from '../../../constants/Routes';

const DashboardHome = () => {
  const [isLoading, setIsLoading] = useState(true)
  const { projectTypes, setProjectsDirty } = useApp()
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (projectTypes.length) {
      setIsLoading(false)
    }
  }, [projectTypes])

  const createProject = (e, project) => {
    e.preventDefault()
    addNewProject(currentUser.uid, project)
      .then(id => {
        setProjectsDirty(true)
        navigate(routes.PROJECT + '/' + id)
      })

  }

  const buttonClasses = `w-full py-2 mb-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-600 duration-150`

  return (
    <>
      {isLoading ?
        <>
          {/* <div style={{ display: 'flex', gap: '15px', justifyContent: 'flex-start' }}>
            <CardSkeleton />
            <CardSkeleton />
          </div> */}
        </>
        :
        <section className="mx-auto max-w-screen-xl pb-4 px-4 py-4 sm:px-8">
          <h1 className="text-gray-800 font-bold text-2xl md:text-3xl">
            Create a New Avatar
          </h1>
          <div className="grid gap-x-8 gap-y-10 sm:grid-cols-2 lg:grid-cols-4">
            {projectTypes.map((p) => {
              return <div className="border rounded-[25px]" key={uuidv4()}>
                <img src={p.ThumbnailUrl} loading="lazy" alt={p.Name} className="w-full" />
                <div className="mt-3 space-y-2">
                  <h3 className="text-lg text-gray-800 duration-150 group-hover:text-indigo-600 font-semibold text-center">
                    {p.Name}
                  </h3>
                  <p className="text-gray-600 text-sm duration-150 group-hover:text-gray-800">{p.Description}</p>
                </div>
                <div className='px-4'>
                  <button onClick={(e) => { createProject(e, p) }} className={buttonClasses}>
                    Create
                  </button>
                </div>                
              </div>
            })}
          </div>
        </section>
      }
    </>

  )
}

export default DashboardHome