import React, { useEffect, useState } from 'react'
import Sidebar from './sidebar'
import { Outlet } from 'react-router-dom';
import { useNavigate, Navigate } from 'react-router-dom';
import routes from '../../constants/Routes';
import { useAuth } from '../../contexts/AuthContext';
import { useLocation } from 'react-router-dom';
import { isDashboardProjectRoute, isRecentProjectRoute } from '../../helper/helper';

const Dashboard = () => {
  const { userLoggedIn, currentUser } = useAuth()
  const [selectedButton, setSelectedButton] = useState(routes.DASHBOARD_HOME)

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (isDashboardProjectRoute(location.pathname)) {
      setSelectedButton(routes.DASHBOARD_PROJECTS)
    } else if (isRecentProjectRoute(location.pathname)) {
      setSelectedButton(routes.DASHBOARD_RECENT_PROJECTS)
    } else {
      setSelectedButton(routes.DASHBOARD_HOME)
    }
  }, [location.pathname])

  const buttonClasses = `w-full py-2 font-medium rounded-sm bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-600 duration-150`

  return (
    <>
      {userLoggedIn && !currentUser.emailVerified && (<Navigate to={routes.EMAIL_NOT_VERIFIED} replace={true} />)}
      <div className="flex flex-col flex-grow h-full">
        <Sidebar>
          <div className='flex flex-col m-auto justify-self-center'>
            <div role={'listitem'} className="mb-2 px-2">
              <button onClick={() => { navigate(routes.DASHBOARD_HOME) }} className={[buttonClasses, selectedButton === routes.DASHBOARD_HOME ? '!bg-amber-300 text-black' : 'text-white'].join(' ')}>
                Home
              </button>
            </div>
            <div role={'listitem'} className="my-2 px-2">
              <button onClick={() => { navigate(routes.DASHBOARD_PROJECTS) }} className={[buttonClasses, selectedButton === routes.DASHBOARD_PROJECTS ? '!bg-amber-300 text-black' : 'text-white'].join(' ')}>
                My Avatars
              </button>
            </div>
            <div role={'listitem'} className="my-2 px-2">
              <button onClick={() => { navigate(routes.DASHBOARD_RECENT_PROJECTS) }} className={[buttonClasses, selectedButton === routes.DASHBOARD_RECENT_PROJECTS ? '!bg-amber-300 text-black' : 'text-white'].join(' ')}>
                Recent Avatars
              </button>
            </div>
          </div>
        </Sidebar>
        <main className="flex-grow px-4 max-w-screen-xl lg:ml-48">
          <Outlet />
        </main>
      </div>
    </>

  )
}

export default Dashboard