import React from "react"
import { useAuth } from '../../contexts/AuthContext'
import routes from "../../constants/Routes"
import { Navigate } from 'react-router-dom';

 export default function PrivateRoute({ children }) {
    const { currentUser } = useAuth()
    if (!currentUser) {
        // not logged in so redirect to login page with the return url
        return <Navigate to={routes.SIGN_IN} replace={true} />
    }

    // authorized so return child components
    return children;
}


