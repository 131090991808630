import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { isFooterAvailable } from '../../helper/helper';
import { Link } from 'react-router-dom';

const Footer = () => {
  const location = useLocation()
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    setIsVisible(isFooterAvailable(location.pathname))
  }, [location.pathname])

  if (!isVisible) {
    return null
  }

  return (
    <footer className="py-10 bg-gray-800">
      <div className="max-w-screen-xl mx-auto px-4 md:px-8">
        <div className="flex flex-col sm:flex">
          <h5 className="text-gray-300 text-lg font-medium">Avatar Smith</h5>
          <p className="text-gray-300">This product is currently in alpha and may contain bugs.</p>
          <p className="text-gray-300">March 2023 Release</p> 
          <p className="text-gray-300"><Link className='underline text-indigo-400 duration-150 hover:text-indigo-300 font-medium' to={'faq'}>Frequently Asked Questions</Link></p>

        </div>
      </div>
    </footer>
  );
}

export default Footer