class ImageCache {
    constructor() {
        this.openDb();
    }

    openDb() {
        let request = window.indexedDB.open("image_cache", 1);

        request.onupgradeneeded = event => {
            let db = event.target.result;

            // create an object store to hold the images
            let objectStore = db.createObjectStore("images", { keyPath: "id" });

            // create an index to search for images by id
            objectStore.createIndex("id", "id", { unique: true });

            // create an index to search for images by expiration date
            objectStore.createIndex("expiration", "expiration", { unique: false });
        };

        request.onsuccess = event => {
            this.db = event.target.result;
        };
    }

    async cacheImage(id, data, expiresIn = 604800000) {
        let transaction = this.db.transaction(["images"], "readwrite");
        let objectStore = transaction.objectStore("images");

        // add the image to the object store
        let expiration = Date.now() + expiresIn;
        let image = { id, data, expiration };
        objectStore.put(image);
    }

    async getCachedImage(id) {
        
        let transaction = this.db.transaction(["images"], "readonly");
        let objectStore = transaction.objectStore("images");

        // retrieve the image from the object store
        let image = await objectStore.get(id);

        // check if the image is expired
        if (image && image.expiration < Date.now()) {
            // delete the expired image from the object store
            objectStore.delete(id);
            return null;
        }

        return image && image.data ? image.data : null;
    }

    async hasCachedImage(id) {
        let transaction = this.db.transaction(["images"], "readonly");
        let objectStore = transaction.objectStore("images");

        let image = await objectStore.get(id);
        return !!(image && image.data);
    }
}

export default new ImageCache();
