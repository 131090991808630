import React, { useState } from 'react'
import { Navigate, Link } from 'react-router-dom'
import { auth } from '../../firebase'
import { useAuth } from '../../contexts/AuthContext'
import { validateEmail } from '../../helper/helper'
import { ErrorMessageMap } from '../../constants/ErrorMessageMap'
import routes from '../../constants/Routes'
import CustomAlert from './../alert/index'

const ForgetPassword = () => {
    const [email, setEmail] = useState('')
    const [isSendingEmail, setIsSendingEmail] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')

    const { userLoggedIn } = useAuth()

    const onSubmit = (e) => {
        e.preventDefault()

        // reset the success message
        setSuccessMessage('')

        // validate form values
        if (!validateEmail(email)) {
            setErrorMessage('Please enter a valid email address.');
            return;
        }

        // If form values are valid, reset error message and proceed with login
        setErrorMessage('');

        setIsSendingEmail(true)
        auth.doPasswordReset(email)
            .then(() => {
                setIsSendingEmail(false)
                setSuccessMessage('Password reset email has been sent. Please check your inbox and spam folders.')
            })
            .catch(err => {
                setIsSendingEmail(false)

                if (ErrorMessageMap[err.code]) {
                    setErrorMessage(ErrorMessageMap[err.code]);
                }
                else {
                    setErrorMessage(ErrorMessageMap['default']);
                }

                console.log(err)
            })
    }

    return (
        <>
            {userLoggedIn && (<Navigate to={routes.DASHBOARD} replace={true} />)}
            <main className="w-full h-full flex flex-col items-center justify-center px-4">
                <div className="max-w-sm w-full text-gray-600 space-y-5">
                    <div className="text-center pb-8">
                        <div className="mt-2">
                            <h3 className="text-gray-800 text-2xl font-bold sm:text-3xl">Get a new password</h3>
                        </div>
                    </div>
                    <form
                        onSubmit={onSubmit}
                        className="space-y-5"
                    >
                        <div>
                            <label className="font-medium">
                                Email
                            </label>
                            <input
                                type="email"
                                autoComplete='email'
                                required
                                value={email} onChange={(e) => { setEmail(e.target.value) }}
                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                            />
                        </div>

                        {errorMessage && (
                            <CustomAlert type="danger" heading={'Error'} subheading={errorMessage}></CustomAlert>
                        )}

                        {successMessage && (
                            <CustomAlert type="success" heading={'Success'} subheading={successMessage}></CustomAlert>
                        )}

                        <button
                            type="submit"
                            disabled={isSendingEmail}
                            className={`w-full px-4 py-2 text-white font-medium rounded-lg ${isSendingEmail ? 'bg-indigo-300 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-600 duration-150'}`}
                        >
                            {isSendingEmail ? 'Sending Password Reset Email...' : 'Reset Password'}
                        </button>
                    </form>

                    <p className="text-center">Remember it after all? {'   '} <Link to={routes.SIGN_IN} className="font-medium text-indigo-600 hover:text-indigo-500">Sign In</Link></p>
                </div>
            </main>
        </>
    )
}

export default ForgetPassword