import { getFileUrl } from "../firebase/storage";
import { fetchByDotOperator } from "../helper/helper";
import ImageCache from "../helper/ImageCache";
import Vibrant from "node-vibrant";

const blockMap = {
  character: {
    typeName: "character",
  },
  eyes: {
    typeName: "eyes",
  },
  face: {
    typeName: "face",
  },
  makeup: {
    typeName: "makeup",
  },
  lips: {
    typeName: "lips",
  },
  clothing: {
    typeName: "clothing",
  },
  hair: {
    typeName: "hair",
  },
  beard: {
    typeName: "beard",
  },
  accessory_eyewear: {
    typeName: "accessory_eyewear",
  },
};

const getImgUrl = (projectType, folder, blockId, imageType) => {
  return `avatars/${projectType.Type}/${folder}/${blockId}.${imageType}`;
};

const AvatarManager = {
  loadAvatarCanvas: async (project, projectType, canvas) => {
    let imgCount = 0;
    const context = canvas.getContext("2d");

    const order = [];
    let blockMissing = false;
    let missingBlocks = [];

    let blockId, maskId;
    
    const myPromise = new Promise((resolve, reject) => {
      try {
        // character
        handleCharacterBlock();

        // eyes
        handleEyesBlock();

        // makeup

        // lips
        handleLipsBlock();

        // eyewear
        handleEyewearBlock();

        // clothing
        handleClothingBlock();

        // beard
        handleBeardBlock();

        // hair
        handleHairBlock();
        
        // if any block missing, then reject the promise
        if (blockMissing) {
          reject(`Block image missing: ${missingBlocks.join(", ")}`);
        }

        const orderWithFileUrl = order.map(async (block) => {
          var retVal = { ...block };
          if (!(await ImageCache.hasCachedImage(block.imgId))) {
            const url = await getFileUrl(block.imgUrl);
            retVal = { ...retVal, fileUrl: url };
          }

          var maskUrl;
          if (block.maskUrl) {
            if (!(await ImageCache.hasCachedImage(block.maskId))) {
              maskUrl = await getFileUrl(block.maskUrl);
              retVal = { ...retVal, maskImage: maskUrl };
            }
          }

          return retVal;
        });

        Promise.all(orderWithFileUrl).then((results) => {
          var orderWithImgBlob = results.map(async (block) => {
            var imgResponse, imgBlob;
            var retVal = { ...block };
            imgBlob = await ImageCache.getCachedImage(block.imgId);
            if (!imgBlob) {
              imgResponse = await fetch(block.fileUrl);
              imgBlob = await imgResponse.blob();
              ImageCache.cacheImage(block.imgId, imgBlob);
            }
            retVal = { ...retVal, imgBlob: imgBlob };

            if (block.maskUrl) {
              let maskResponse, maskBlob;
              maskBlob = await ImageCache.getCachedImage(block.maskId);
              if (!maskBlob) {
                maskResponse = await fetch(block.maskImage);
                maskBlob = await maskResponse.blob();
                ImageCache.cacheImage(block.maskId, maskBlob);
              }
              retVal = { ...retVal, maskBlob: maskBlob };
            }

            return retVal;
          });

          Promise.all(orderWithImgBlob)
            .then((results) => {
              results.forEach((block) => {
                if (block.maskUrl) {
                  let maskCanvas = document.createElement("canvas");
                  const orderItem = order.find((o) => {
                    return o.blockType === block.blockType;
                  });
                  orderItem.isCanvas = true;
                  orderItem.canvas = maskCanvas;
                  maskCanvas.width = canvas.width;
                  maskCanvas.height = canvas.height;

                  let img = new Image();
                  img.setAttribute("data-name", block.blockType);
                  img.src = URL.createObjectURL(block.maskBlob);

                  img.onload = function () {
                    let idx = order.findIndex(
                      (item) =>
                        item.blockType === this.getAttribute("data-name")
                    );

                    if (idx > -1) {
                      let maskCtx = order[idx].canvas.getContext("2d");
                      applyContextSmoothening(maskCtx);
                      maskCtx.imageSmoothingQuality = "high";

                      maskCtx.drawImage(this, 0, 0);
                      const imageData = maskCtx.getImageData(
                        0,
                        0,
                        order[idx].canvas.width,
                        order[idx].canvas.height
                      );
                      const data = imageData.data;

                      for (let i = 0; i < data.length; i += 4) {
                        let red = data[i];
                        let green = data[i + 1];
                        let blue = data[i + 2];
                        // let alpha = data[i+3]

                        if (!(red === 0 && green > 200 && blue === 0)) {
                          data[i + 3] = 0;
                        }
                      }

                      maskCtx.putImageData(imageData, 0, 0);

                      let img = new Image();
                      img.setAttribute("data-name", block.blockType);
                      img.src = URL.createObjectURL(block.imgBlob);

                      img.onload = function () {
                        imgCount++;

                        // set the image in the array
                        let idx = order.findIndex(
                          (item) =>
                            item.blockType === this.getAttribute("data-name")
                        );
                        if (idx > -1) {
                          let maskCtx = order[idx].canvas.getContext("2d");
                          applyContextSmoothening(maskCtx);
                          maskCtx.imageSmoothingQuality = "high";
                          maskCtx.globalCompositeOperation = "source-in";
                          maskCtx.drawImage(this, 0, 0);
                        }

                        if (imgCount === order.length) {
                          loadCanvasImages();
                        }
                      };
                    }
                  };
                } else {
                  let img = new Image();
                  img.setAttribute("data-name", block.blockType);
                  img.src = URL.createObjectURL(block.imgBlob);
                  img.onload = imgLoad;
                }
              });
            })
            .catch((error) => {
              console.error("An error occurred:", error);
            });
        });

        function imgLoad() {
          imgCount++;

          // set the image in the array
          let idx = order.findIndex(
            (item) => item.blockType === this.getAttribute("data-name")
          );
          if (idx > -1) {
            order[idx].image = this;
          }

          if (imgCount === order.length) {
            loadCanvasImages();
          }
        }

        async function loadCanvasImages() {
          function getResolvedPromise(success){
            return {
              success: success
            }
          }
          
          const tempCanvas = document.createElement("canvas");
          tempCanvas.width = canvas.width;
          tempCanvas.height = canvas.height;
          const tempContext = tempCanvas.getContext("2d");

          context.clearRect(0, 0, canvas.width, canvas.height);

          order.forEach((block) => {
            var blockToDraw = block.isCanvas ? block.canvas : block.image;
            tempContext.drawImage(
              blockToDraw,
              0,
              0,
              canvas.width,
              canvas.height
            );
          });

          if (project.Background.HasBackground && project.Background.Type === "g") {
            const vibrant = new Vibrant(tempCanvas.toDataURL());
            vibrant.getPalette((err, palette) => {
              var grd = context.createRadialGradient(
                canvas.width / 2,
                canvas.height / 2,
                0,
                canvas.width / 2,
                canvas.height / 2,
                canvas.height
              );

              grd.addColorStop(0, palette.LightVibrant.hex);
              grd.addColorStop(0.5, palette.Vibrant.hex);
              grd.addColorStop(1, palette.DarkVibrant.hex);

              // fill the rectangle with the gradient
              context.fillStyle = grd;
              context.fillRect(0, 0, canvas.width, canvas.height);

              context.drawImage(tempCanvas, 0, 0, canvas.width, canvas.height);
                
              resolve(getResolvedPromise(true));
            });
          } else if (project.Background.HasBackground && (project.Background.Type === "c" && project.Background.Selection.length)) {
            // get the background id
            const backgroundId = project.Background.Selection

            // get the background storage url
            const storageUrl = getImgUrl(projectType, 'backgrounds', backgroundId, 'jpg')

            // get the background file url
            var backgroundUrl
            if (!(await ImageCache.hasCachedImage(backgroundId))) {
              backgroundUrl = await getFileUrl(storageUrl);              
            }

            // set the image in img element
            var bgBlob = await ImageCache.getCachedImage(backgroundId);
            if (!bgBlob) {
              let bgResponse = await fetch(backgroundUrl);
              bgBlob = await bgResponse.blob();
              ImageCache.cacheImage(backgroundId, bgBlob);
            }

            // in img element onload, draw the background and then the avatar on the canvas
            let bgImg = new Image();
            bgImg.src = URL.createObjectURL(bgBlob);
            bgImg.onload = function(){
              context.drawImage(this, 0, 0, canvas.width, canvas.height);
              context.drawImage(tempCanvas, 0, 0, canvas.width, canvas.height);

              resolve(getResolvedPromise(true));
            };

          } else {
            context.drawImage(tempCanvas, 0, 0, canvas.width, canvas.height);
            resolve(getResolvedPromise(true));
          }
        }
      } catch (error) {
        console.log(error)
        reject({
          success: false,
          error: error
        });
      }
    });

    function handleCharacterBlock() {
      blockId = fetchByDotOperator(
        projectType,
        `Body.${project.Character}.Poses.${project.Pose}.Expressions.${project.Expression}.Skins.${project.Skin}.Id`
      );
      if (!blockId.length) {
        blockMissing = true;
        missingBlocks.push(blockMap.character.typeName);
      } else {
        order.push(
          getOrderItem(
            blockMap.character.typeName,
            blockId,
            getImgUrl(projectType, "expression", blockId, 'png')
          )
        );
      }
    }

    function handleEyesBlock() {
      if (projectType.Eyes.Available) {
        blockId = fetchByDotOperator(
          projectType,
          `Body.${project.Character}.Poses.${project.Pose}.Expressions.${project.Expression}.Eyes.${project.Eyes}.Id`
        );
        maskId = fetchByDotOperator(
          projectType,
          `Body.${project.Character}.Poses.${project.Pose}.Expressions.${project.Expression}.Eyes.Mask.Id`
        );
        if (!blockId.length || !maskId.length) {
          blockMissing = true;
          missingBlocks.push(blockMap.eyes.typeName);
        } else {
          order.push(
            getOrderItem(
              blockMap.eyes.typeName,
              blockId,
              getImgUrl(projectType, "eyes", blockId, 'png'),
              maskId,
              getImgUrl(projectType, "eyes", maskId, 'png')
            )
          );
        }
      }
    }

    function handleLipsBlock() {
      if (
        projectType.Lips.Available &&
        project.Lips.Enabled &&
        project.Lips.Selection.length
      ) {
        blockId = fetchByDotOperator(
          projectType,
          `Body.${project.Character}.Poses.${project.Pose}.Expressions.${project.Expression}.Skins.${project.Skin}.Lips.${project.Lips.Selection}.Id`
        );
        maskId = fetchByDotOperator(
          projectType,
          `Body.${project.Character}.Poses.${project.Pose}.Expressions.${project.Expression}.Skins.${project.Skin}.Lips.Mask.Id`
        );
        if (!blockId.length || !maskId.length) {
          blockMissing = true;
          missingBlocks.push(blockMap.lips.typeName);
        } else {
          order.push(
            getOrderItem(
              blockMap.lips.typeName,
              blockId,
              getImgUrl(projectType, "lips", blockId, 'png'),
              maskId,
              getImgUrl(projectType, "lips", maskId, 'png')
            )
          );
        }
      }
    }

    function handleClothingBlock() {
      if (projectType.Clothing.Available && project.Clothing.Enabled) {
        blockId = fetchByDotOperator(
          projectType,
          `Body.${project.Character}.Poses.${project.Pose}.Expressions.${project.Expression}.Clothes.${project.Clothing.Selection}.Id`
        );
        if (!blockId.length) {
          blockMissing = true;
          missingBlocks.push(blockMap.clothing.typeName);
        } else {
          order.push(
            getOrderItem(
              blockMap.clothing.typeName,
              blockId,
              getImgUrl(projectType, "clothing", blockId, 'png')
            )
          );
        }
      }
    }

    function handleHairBlock() {
      if (projectType.Hair.Available && project.Hair.Enabled && project.Hair.Selection.length) {
        blockId = fetchByDotOperator(
          projectType,
          `Body.${project.Character}.Poses.${project.Pose}.Expressions.${project.Expression}.Hairs.${project.Hair.Selection}.Id`
        );
        if (!blockId.length) {
          blockMissing = true;
          missingBlocks.push(blockMap.hair.typeName);
        } else {
          order.push(
            getOrderItem(
              blockMap.hair.typeName,
              blockId,
              getImgUrl(projectType, "hair", blockId, 'png')
            )
          );
        }
      }
    }

    function handleBeardBlock() {
      if (projectType.Beard.Available && project.Beard.Enabled && project.Beard.Selection.length) {
        blockId = fetchByDotOperator(
          projectType,
          `Body.${project.Character}.Poses.${project.Pose}.Expressions.${project.Expression}.Beards.${project.Beard.Selection}.Id`
        );
        if (!blockId.length) {
          blockMissing = true;
          missingBlocks.push(blockMap.beard.typeName);
        } else {
          order.push(
            getOrderItem(
              blockMap.beard.typeName,
              blockId,
              getImgUrl(projectType, "beard", blockId, 'png')
            )
          );
        }
      }
    }

    function handleEyewearBlock() {
      if (projectType.Accessory_Eyewear.Available && project.Accessory_Eyewear.Enabled && project.Accessory_Eyewear.Selection.length) {
        blockId = fetchByDotOperator(
          projectType,
          `Body.${project.Character}.Poses.${project.Pose}.Expressions.${project.Expression}.Accessory_Eyewear.${project.Accessory_Eyewear.Selection}.Id`
        );
        if (!blockId.length) {
          blockMissing = true;
          missingBlocks.push(blockMap.accessory_eyewear.typeName);
        } else {
          order.push(
            getOrderItem(
              blockMap.accessory_eyewear.typeName,
              blockId,
              getImgUrl(projectType, "accessory_eyewear", blockId, 'png')
            )
          );
        }
      }
    }

    function getOrderItem(blockType, imgId, imgUrl, maskId, maskUrl) {
      return {
        blockType: blockType,
        imgId: imgId,
        maskId: maskId,
        imgUrl: imgUrl,
        maskUrl: maskUrl,
        image: null,
        maskImage: null,
      };
    }

    function applyContextSmoothening(ctx) {
      // Set image smoothing for all browsers
      if (ctx.imageSmoothingEnabled !== undefined) {
        ctx.imageSmoothingEnabled = true;
      } else if (ctx.webkitImageSmoothingEnabled !== undefined) {
        ctx.webkitImageSmoothingEnabled = true;
      } else if (ctx.mozImageSmoothingEnabled !== undefined) {
        ctx.mozImageSmoothingEnabled = true;
      } else if (ctx.msImageSmoothingEnabled !== undefined) {
        ctx.msImageSmoothingEnabled = true;
      }
    }

    return myPromise
  },
};

export default AvatarManager;
