import moment from "moment";
import routes from "../constants/Routes";

export const getCurrentDateTime = () => {
  // Create a moment object representing the current date and time in UTC
  var d = moment.utc();

  // Return the date and time as a formatted string
  return getFormattedDate(d);
};

function getFormattedDate(d){
  const format = "YYYY-MM-DD HH:mm:ss"
  return d.format(format);
}

export const getDateFromString = (dateTimeString) => {
  // Use the Date constructor to create a new Date object from the string
  return new Date(dateTimeString);
};

export const getDateTimeStringLocal = (dateTimeString) => {
  // Convert a UTC time string to a Moment object
  const utcTime = moment.utc(dateTimeString);

  // Convert the UTC time to the user's local time
  return getFormattedDate(utcTime.local());
};

export const getDateString = () => {
  const currentDate = new Date();

  const month = currentDate.getMonth() + 1; // months are 0-based
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();

  const mmddyyyy = `${month.toString().padStart(2, "0")}${day
    .toString()
    .padStart(2, "0")}${year}`;

  return mmddyyyy;
};

export const convertDateStringToFriendlyDate = (dateString) => {
  // Create a new JavaScript Date object from the date string
  const date = new Date(dateString);

  // Get the current year
  const currentYear = new Date().getFullYear();

  // Get the month and day from the date object
  const month = date.toLocaleString('default', { month: 'long' });
  const day = date.getDate();

  // If the date is in the current year, just return the month and day
  if (date.getFullYear() === currentYear) {
    return `${month} ${day}`;
  }
  // Otherwise, return the month, day, and year
  return `${month} ${day}, ${date.getFullYear()}`;
}

export const mmddyyyyToDate = (mmddyyyy) => {
  const month = mmddyyyy.substr(0, 2);
  const day = mmddyyyy.substr(2, 2);
  const year = mmddyyyy.substr(4);

  return new Date(year, month - 1, day);
};

export const deepCopyArray = (arr) => {
  return arr.map((item) => {
    if (typeof item === "object" && item !== null) {
      if (Array.isArray(item)) {
        // item is an array, so we need to recursively call deepCopyArray
        return deepCopyArray(item);
      } else {
        // item is an object, so we need to use Object.assign to create a copy
        return Object.assign({}, item);
      }
    } else {
      // item is a primitive value, so we can just return it
      return item;
    }
  });
};

export const deepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj));
}

// Function to validate email
export const validateEmail = (email) => {
  // Regular expression to match a valid email address
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  return emailRegex.test(email);
}

export const validatePassword = (password) => {
  // Regular expression to match a password with at least 8 characters,
  // a number, and a special character
  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
  return passwordRegex.test(password);
}

export const fetchByDotOperator = (object, value) => {
  return value.split('.').reduce((acc, curr) => {
    return acc[curr]
  }, object);
}

export const isProjectRoute = (pathname)=>{
  return pathname.indexOf(routes.PROJECT) > -1 && pathname.indexOf(routes.DASHBOARD_PROJECTS) === -1
}

export const isDashboardProjectRoute = (pathname)=>{
  return pathname.indexOf(routes.DASHBOARD_PROJECTS) > -1 && pathname.indexOf(routes.DASHBOARD_RECENT_PROJECTS) === -1
}

export const isRecentProjectRoute = (pathname)=>{
  return pathname.indexOf(routes.DASHBOARD_RECENT_PROJECTS) > -1
}

export const isDashboardRoute = (pathname)=>{
  return pathname.indexOf(routes.DASHBOARD) > -1
}

export const isFooterAvailable = (pathname)=>{
  return pathname.indexOf(routes.PROJECT) === -1 && pathname.indexOf(routes.DASHBOARD) === -1 && pathname.indexOf(routes.PROFILE)
}

export const randomizeProject= (project, projectType) =>{
  // get random clothing
  const clothingTypes = Object.keys(projectType.Clothing.Types)
  const randomClothing = clothingTypes[Math.floor(Math.random() * clothingTypes.length)];

  // get random hair
  const hairTypes = Object.keys(projectType.Hair.Types)
  const randomHair = hairTypes[Math.floor(Math.random() * hairTypes.length)];

  // get random lips
  const lipTypes = Object.keys(projectType.Lips.Types)
  const randomLips = lipTypes[Math.floor(Math.random() * lipTypes.length)];

  // get random eyes
  const eyeTypes = Object.keys(projectType.Eyes.Types)
  const randomEyes = eyeTypes[Math.floor(Math.random() * eyeTypes.length)];

  // get random expression
  const expressionTypes = Object.keys(projectType.Expression.Types)
  const randomExpression = expressionTypes[Math.floor(Math.random() * expressionTypes.length)];

  // get random pose
  const poseTypes = Object.keys(projectType.Pose.Types)
  const randomPose = poseTypes[Math.floor(Math.random() * poseTypes.length)];

  // get random skin
  const skinTypes = Object.keys(projectType.Skin.Types)
  const randomSkin = skinTypes[Math.floor(Math.random() * skinTypes.length)];

  // assign the random values

  // clothing
  const clothingObj = { ...project.Clothing, Selection: randomClothing }

  // hair
  const hairObj = { ...project.Hair, Enabled: true, Selection: randomHair }


  // lips
  const lipsObj = { ...project.Lips, Enabled: true, Selection: randomLips }

  // eyes
  const eyesObj = randomEyes

  // expression
  const expressionObj = randomExpression

  // pose
  const poseObj = randomPose

  // skin
  const skinObj = randomSkin

  return {clothingObj, hairObj, lipsObj, eyesObj, expressionObj, poseObj, skinObj }
}