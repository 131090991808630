import React, { useState, useEffect, Fragment } from 'react'

import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';

const LegalTerms = () => {

    const tabItems = [{ title: "Terms of Service", component: <TermsOfService /> }, { title: "Privacy Policy", component: <PrivacyPolicy /> }]
    const [selectedItem, setSelectedItem] = useState(0)
    const [selectedComponent, setSelectedComponent] = useState(null)

    useEffect(()=>{
        setSelectedComponent(tabItems[selectedItem].component)
    }, [selectedItem])

    return (
        <Fragment>
            <div className="px-4 md:px-8">
                <ul role="tablist" className="max-w-screen-xl mx-auto border-b flex items-center gap-x-3 overflow-x-auto text-sm">
                    {
                        tabItems.map((item, idx) => (
                            <li key={idx} className={`py-2 border-b-2 ${selectedItem === idx ? "border-indigo-600 text-indigo-600" : "border-white text-gray-500"}`}>
                                <button
                                    role="tab"
                                    aria-selected={selectedItem === idx ? true : false}
                                    aria-controls={`tabpanel-${idx + 1}`}
                                    className="py-2.5 px-4 rounded-lg duration-150 hover:text-indigo-600 hover:bg-gray-50 active:bg-gray-100 font-medium text-xl"
                                    onClick={() => setSelectedItem(idx)}>
                                    {item.title}
                                </button>
                            </li>
                        ))
                    }
                </ul>
            </div>
            <div className="max-w-screen-xl mx-auto px-4 md:px-8">
                {selectedComponent}
            </div>
        </Fragment>
    )
}

export default LegalTerms