export const ErrorMessageMap = {
    'auth/wrong-password': 'The password is incorrect.',

    'auth/user-not-found': 'User not found, please verify and enter correct email.',

    'auth/too-many-requests': 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.',

    'auth/email-already-in-use': 'The email id is already in use. Please enter another email.',    

    'auth/requires-recent-login': 'For security reasons, you have to be recently logged-in to change the password. Please log out and log-in again.',    

    'default': 'Something went wrong, please verify the inputs and try again.',


}