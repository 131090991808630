import React, { Fragment } from 'react'

const TermsOfService = () => {
  return (
    <Fragment>
      <div className='py-2'>
        <h3 className='text-lg font-medium'>Terms of Service</h3>
        <p>These Terms of Service (“Terms”) are between you and Avatar Smith, (“Website”) and govern your use of the Avatar Smith website, including all pages within the website (collectively, the “Website”). By accessing, browsing, and using the Website, you agree to be bound by these Terms. If you do not agree to these Terms, you should not use the Website.</p>
      </div>

      <div className='py-2'>
        <h3 className='text-lg font-medium'>Accounts</h3>
        <p>To use some of the features on the Website, you may be required to create an account (“Account”). You represent and warrant that all information you provide to us when creating your Account is accurate, current, and complete, and you agree to maintain and update your Account information to keep it accurate, current, and complete. You are solely responsible for the activity that occurs on your Account, and you must keep your Account password confidential. You must notify us immediately of any unauthorized use of your Account. We are not liable for any loss or damage arising from your failure to comply with this Section.</p>

      </div>

      <div className='py-2'>
        <h3 className='text-lg font-medium'>Use of the Website</h3>
        <p>The Website and its content, including all graphics, text, images, and other materials, are the property of the Website and are protected by international copyright and other intellectual property laws. You may use the Website and its content for personal, non-commercial purposes only. You may not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, sell or re-sell any information, software, products, or services obtained from the Website without our express written consent.</p>

      </div>

      <div className='py-2'>
        <h3 className='text-lg font-medium'>Free Items</h3>
        <p>The Website may offer certain items for free, including, but not limited to, 2D avatar building blocks such as characters, clothes, hairs, etc. (“Free Items”). You may access, use, and download the Free Items for personal, non-commercial purposes only. You may not sell or re-sell any Free Items.</p>

      </div>

      <div className='py-2'>
        <h3 className='text-lg font-medium'>User Data</h3>
        <p>The Website may receive and collect information that you provide, including, but not limited to, your account information and feedback. You grant the Website a non-exclusive, worldwide, perpetual, irrevocable, transferable, royalty-free license to use, copy, modify, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, and otherwise exploit your feedback for any purpose, including, but not limited to, improving the Website and creating other products and services. You understand and agree that the Website may use your feedback for promotional purposes and may share your feedback with third parties, including, but not limited to, other users of the Website.</p>

      </div>

      <div className='py-2'>
        <h3 className='text-lg font-medium'>Promotional Emails</h3>
        <p>By creating an Account on the Website, you agree to receive promotional emails from the Website. You may opt-out of receiving promotional emails by following the unsubscribe instructions in the promotional emails.</p>

      </div>

      <div className='py-2'>
        <h3 className='text-lg font-medium'>Limitation of Liability</h3>
        <p>The Website and its owners, directors, officers, employees, agents, suppliers, and licensors will not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including, but not limited to, loss of profits, data, use, goodwill, or other intangible losses,resulting from (i) your access to or use of or inability to access or use the Website; (ii) any conduct or content of any third party on the Website, including, but not limited to, any defamatory, offensive, or illegal conduct of other users or third parties; (iii) any content obtained from the Website; and (iv) unauthorized access, use, or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.</p>

      </div>

      <div className='py-2'>
        <h3 className='text-lg font-medium'>Indemnification</h3>
        <p>You agree to defend, indemnify, and hold harmless the Website and its owners, directors, officers, employees, agents, suppliers, and licensors, from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys’ fees) arising out of your use of the Website and its content, your violation of these Terms, or your violation of any rights of another.</p>

      </div>

      <div className='py-2'>
        <h3 className='text-lg font-medium'>Termination</h3>
        <p>We may terminate or suspend your access to the Website, your Account, and your ability to use the Free Items, at any time, with or without cause, with or without notice, and without liability to you. Upon termination, your right to use the Website, your Account, and the Free Items will immediately cease. If you wish to terminate these Terms, you may discontinue using the Website.</p>

      </div>

      <div className='py-2'>
        <h3 className='text-lg font-medium'>Disclaimer of Warranties</h3>
        <p>The Website and its content are provided on an “as is” and “as available” basis. The Website and its owners, directors, officers, employees, agents, suppliers, and licensors, make no representations or warranties of any kind, express or implied, as to the operation of the Website, the information, content, materials, or products included on the Website. You expressly agree that your use of the Website is at your sole risk.</p>
        <p>The Website and its owners, directors, officers, employees, agents, suppliers, and licensors, make no representations or warranties of any kind, express or implied, as to the completeness, accuracy, reliability, suitability, or availability with respect to the Website or the information, products, services, or related graphics contained on the Website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>
        <p>The Website and its owners, directors, officers, employees, agents, suppliers, and licensors, make no representations or warranties of any kind, express or implied, that the Website and its content will meet your requirements, that access to the Website will be uninterrupted, timely, secure, or error-free, that the information provided through the Website is accurate, reliable, or correct, that any defects or errors will be corrected, or that the Website or the server that makes it available are free of viruses or other harmful components. You understand and agree that your use of any information or materials on the Website is entirely at your own risk and that the Website is provided “as is” and “as available” without warranty of any kind.</p>
        <p>No advice or information, whether oral or written, obtained by you from the Website or its owners, directors, officers, employees, agents, suppliers, or licensors, shall create any warranty not expressly stated in these Terms.</p>

      </div>

      <div className='py-2'>
        <h3 className='text-lg font-medium'>Miscellaneous</h3>
        <p>These Terms constitute the entire agreement between you and the Website with respect to the use of the Website and its content. If any provision of these Terms is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced. You agree that these Terms and your use of the Website are governed under international law. We reserve the right to change these Terms at any time and without notice, so it is important to review these Terms regularly. Your continued use of the Website after any changes to these Terms indicates your acceptance of the changes. Our failure to enforce any right or provision in these Terms shall not constitute a waiver of such right or provision unless acknowledged and agreed to by us in writing.</p>

      </div>


    </Fragment>

  )
}

export default TermsOfService