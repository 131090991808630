import React, { useState } from 'react'
import { validatePassword } from '../../../helper/helper';
import { ErrorMessageMap } from '../../../constants/ErrorMessageMap';
import Alert from '../../alert';
import { auth } from '../../../firebase';

const ChangePassword = () => {
    const [newPassword, setNewPassword] = useState('')
    const [confirmedNewPassword, setConfirmedNewPassword] = useState('')
    const [isChangingPassword, setIsChangingPassword] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const onSubmit = (e) => {
        e.preventDefault()

        // reset the success message
        setSuccessMessage('')

        if (!validatePassword(newPassword)) {
            setErrorMessage('Please enter a valid password. It should be at least 8 characters long and should contain a number and a special character.');
            return;
        }

        if (confirmedNewPassword !== newPassword) {
            setErrorMessage('New Password and Confirm Password Do Not Match.');
            return;
        }

        // If form values are valid, reset error message and proceed with login
        setErrorMessage('');

        setIsChangingPassword(true)
        if (newPassword === confirmedNewPassword) {
            auth.doPasswordChange(newPassword)
                .then(() => {
                    setIsChangingPassword(false)
                    setNewPassword('')
                    setConfirmedNewPassword('')
                    setSuccessMessage('Password changed successfully!')
                })
                .catch(err => {
                    setIsChangingPassword(false)
                    if (ErrorMessageMap[err.code]) {
                        setErrorMessage(ErrorMessageMap[err.code]);
                    }
                    else {
                        setErrorMessage(ErrorMessageMap['default']);
                    }
                    console.log(err)
                })
        }

    }
    return (
        <form onSubmit={onSubmit}>
            <div>
                <label className="block py-3 text-gray-500">
                    New Password
                </label>
                <div className="md:w-1/2 flex items-center p-2 border rounded-md">
                    <input
                        value={newPassword}
                        onChange={(e) => { setNewPassword(e.target.value) }}
                        autoComplete='new-password'
                        type="password"
                        className="w-full p-1 ml-3 text-gray-500 outline-none bg-transparent"
                    />
                </div>
            </div>

            <div>
                <label className="block py-3 text-gray-500">
                    Confirm Password
                </label>
                <div className="md:w-1/2 flex items-center p-2 border rounded-md">
                    <input
                        value={confirmedNewPassword}
                        onChange={(e) => { setConfirmedNewPassword(e.target.value) }}
                        autoComplete='disabled'
                        type="password"
                        className="w-full p-1 ml-3 text-gray-500 outline-none bg-transparent"
                    />
                </div>
            </div>
            {errorMessage && (
                <div className='py-3 w-full md:w-1/2'>
                    <Alert type="danger" heading={'Error'} subheading={errorMessage}></Alert>
                </div>

            )}

            {successMessage && (
                <div className='py-3 w-full md:w-1/2'>
                    <Alert type="success" heading={'Success'} subheading={successMessage}></Alert>
                </div>

            )}


            <button
                type="submit"
                disabled={isChangingPassword}
                className={`${isChangingPassword ? 'bg-indigo-300 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700'} my-3 px-4 py-2 font-medium text-white rounded-lg duration-150 active:shadow-lg`}>
                {isChangingPassword ? 'Changing Password...' : 'Change Password'}
            </button>


        </form>
    )
}

export default ChangePassword