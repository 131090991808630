import React from 'react'
import { Switch } from '@headlessui/react'

const SwitchButton = ({checked, enabled, label, labelClasses, onChange}) => {
    return (
        <Switch.Group>
                {label && <Switch.Label className = {`${labelClasses && labelClasses} mr-2 text-black`}>{label}</Switch.Label>}
                <Switch
                    checked={checked}
                    onChange={onChange}
                    className={`${checked ? 'bg-green-600' : 'bg-red-600'
                        } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                >
                    <span
                        className={`${checked ? 'translate-x-6' : 'translate-x-1'
                            } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                    />
                </Switch>
        </Switch.Group>
    )
}

export default SwitchButton