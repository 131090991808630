import React from 'react'

const AvatarItemCard = ({ headerText, toggleButton, cardBody }) => {
    return (
        <>
            <h2 className='text-indigo-600 text-center text-xl font-medium w-full py-2 align-center'>{headerText}</h2>
            {toggleButton && <div className='flex place-content-center my-1 p-1 w-full'>
                {toggleButton}
            </div>}

            {cardBody}
        </>
    )
}

export default AvatarItemCard