import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getProjectById } from '../../firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import { useApp } from '../../contexts/AppContext';
import { Navigate, useNavigate } from 'react-router-dom';
import routes from '../../constants/Routes';
import ProjectCard from './project-card';

const Project = () => {
  const { projectId } = useParams()
  const [project, setProject] = useState(null)
  const [projectType, setProjectType] = useState(null)
  const { userLoggedIn, currentUser } = useAuth()
  const { projectTypes, currentProjectInfo, setCurrentProjectInfo } = useApp()

  const navigate = useNavigate();

  useEffect(() => {
    getProject(projectId)
  }, [])

  useEffect(() => {
    if (project && projectTypes.length && !projectType) {
      getProjectType()
    }

  }, [project, projectTypes])

  async function getProject(projectId) {
    const data = await getProjectById(currentUser.uid, projectId)

    if (data) {
      setProject(data)
      setCurrentProjectInfo({ ...currentProjectInfo, name: data.Name })
    } else {
      navigate(routes.DASHBOARD_PROJECTS)
    }
  }

  async function getProjectType() {
    setProjectType(projectTypes.find(p => p.Type === project.AvatarType))
  }

  return (
    <>
      {userLoggedIn && !currentUser.emailVerified && (<Navigate to={routes.EMAIL_NOT_VERIFIED} replace={true} />)}
      <section className="flex flex-col flex-grow h-full">
        {project && <ProjectCard uid={currentUser.uid} project={project} setProject={setProject} projectType={projectType}></ProjectCard>}
      </section>
    </>
  )
}

export default Project