import React, { useState, useEffect, Fragment } from 'react'
import { auth } from '../../firebase'
import { useAuth } from '../../contexts/AuthContext';
import UserCard from './user-card';
import ChangePassword from './change-password';

import { Navigate } from 'react-router-dom';
import routes from '../../constants/Routes';

const Profile = () => {

    const [isUpdatingProfile, setIsUpdatingProfile] = useState(false)
    const { userLoggedIn, currentUser } = useAuth()

    const updateProfileInformation = (profile) => {
        setIsUpdatingProfile(true)
        auth.updateUserProfile(profile.displayName, profile.photoURL)
            .then(() => {
                setIsUpdatingProfile(false)
                console.log('Profile Updated!')
            })
    }

    const user = {
        displayName: currentUser.displayName,
        email: currentUser.email,
        onSubmit: updateProfileInformation
    };

    const tabItems = [{ title: "User Information", component: <UserCard {...user} isUpdating={isUpdatingProfile} /> }, { title: "Change Password", component: <ChangePassword /> }]
    const [selectedItem, setSelectedItem] = useState(0)
    const [selectedComponent, setSelectedComponent] = useState(null)

    useEffect(() => {
        setSelectedComponent(tabItems[selectedItem].component)
    }, [selectedItem])

    return (
        <>
            {userLoggedIn && !currentUser.emailVerified && (<Navigate to={routes.EMAIL_NOT_VERIFIED} replace={true} />)}
            <Fragment>
                <div className="max-w-screen-xl mx-auto pt-4 px-4 md:px-8">
                    <div className="max-w-lg">
                        <h3 className="text-gray-800 text-2xl font-bold">
                            Profile Information
                        </h3>
                        <p className="text-gray-600 mt-2">
                            View/Update Your Profile Settings
                        </p>
                    </div>
                    <ul role="tablist" className="  border-b flex items-center gap-x-3 overflow-x-auto text-sm">
                        {
                            tabItems.map((item, idx) => (
                                <li key={idx} className={`py-2 border-b-2 ${selectedItem === idx ? "border-indigo-600 text-indigo-600" : "border-white text-gray-500"}`}>
                                    <button
                                        role="tab"
                                        aria-selected={selectedItem === idx ? true : false}
                                        aria-controls={`tabpanel-${idx + 1}`}
                                        className="py-2.5 px-4 rounded-lg duration-150 hover:text-indigo-600 hover:bg-gray-50 active:bg-gray-100 font-medium text-sm lg:text-xl"
                                        onClick={() => setSelectedItem(idx)}
                                    >
                                        {item.title}
                                    </button>
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <div className="max-w-screen-xl mx-auto px-4 md:px-8">
                    {selectedComponent}
                </div>
            </Fragment>
        </>

    )
}

export default Profile