import React, { useState, useEffect, useRef, Fragment } from 'react'
import { Menu, Dialog, Transition } from '@headlessui/react'
import { getDateTimeStringLocal, convertDateStringToFriendlyDate } from '../../../helper/helper';
import { useNavigate } from 'react-router-dom';
import { updateProjectName, removeProject, makeProjectCopy } from '../../../firebase/firestore';
import { useAuth } from '../../../contexts/AuthContext';
import { useApp } from '../../../contexts/AppContext';
import AvatarManager from '../../../avatar/AvatarManager';
import { Cog6ToothIcon } from '@heroicons/react/24/solid'
import routes from '../../../constants/Routes';

const DashboardProjectCard = ({ project, setProjectsDirty }) => {
    const navigate = useNavigate()
    const [isEditing, setIsEditing] = useState(false);
    const [isNameUpdating, setIsNameUpdating] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false)
    const { projectTypes } = useApp()
    const { currentUser } = useAuth()
    const inputRef = useRef(null);
    const canvasRef = useRef(null, (canvas) => {
        if (canvas) {
            canvasRef.current = canvas;
        }
    })

    useEffect(() => {
        if (canvasRef && canvasRef.current && project) {
            loadAvatarCanvas(project, canvasRef.current)
        }

    }, [canvasRef, project])

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }

    }, [isEditing, inputRef]);

    function getProjectType() {
        return projectTypes.find(p => p.Type === project.AvatarType)
    }

    async function loadAvatarCanvas(project, canvas) {
        try {
            await AvatarManager.loadAvatarCanvas(project, getProjectType(), canvas)
        }
        catch (e) {

        }
    }

    const handleTitleSave = (newName) => {
        if (!isNameUpdating) {
            setIsNameUpdating(true)
            updateProjectName(currentUser.uid, project.id, newName.substring(0, 25))
                .then(() => {
                    setProjectsDirty(true)
                    setIsNameUpdating(false)
                    setIsEditing(false);
                })
        }

    };

    const openProject = (e, project) => {
        e.preventDefault()
        navigate(routes.PROJECT + '/' + project.id)
    }

    const copyProject = (e) => {
        e.preventDefault()
        makeProjectCopy(currentUser.uid, project)
            .then(() => {
                setProjectsDirty(true)
            })
    }

    const deleteProject = (e) => {
        e.preventDefault()
        removeProject(currentUser.uid, project.id)
            .then(() => {
                setProjectsDirty(true)
            })

    }

    const buttonClasses = `px-4 py-2 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-600 duration-150`

    function closeDialog() {
        setIsDialogOpen(false)
    }

    function openDialog() {
        setIsDialogOpen(true)
    }

    function RenameDialog({ isDialogOpen, currentName, renameFunc, closeDialog }) {
        const [name, setName] = useState(currentName)
        const renameProject = () => {
            renameFunc(name)
            closeDialog()
        }
        return (
            <Transition appear show={isDialogOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeDialog}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95">
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title>Rename</Dialog.Title>
                                    <div>
                                        <label>
                                            <span className='text-md'>Give your project a new name</span>
                                        </label>
                                        <input
                                            type="text"
                                            required
                                            maxLength={25}
                                            value={name} onChange={(e) => { setName(e.target.value) }}
                                            className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                                        />
                                    </div>
                                    <div className='flex flex-row place-content-end space-x-2 mt-2'>
                                        <button className="px-3 py-1.5 font-medium text-sm text-white duration-150 bg-green-600 rounded-lg hover:bg-green-700 active:shadow-lg" onClick={(e) => renameProject(e)}>Rename</button>
                                        <button className="px-3 py-1.5 font-medium text-sm text-white duration-150 bg-yellow-600 rounded-lg hover:bg-yellow-700 active:shadow-lg" onClick={(e) => closeDialog(e)}>Cancel</button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        )
    }

    return (
        <div className="border rounded-lg">
            <div className="flex items-start justify-between p-4">
                <div className="space-y-2">

                    <h5 className="text-gray-800 font-semibold">{project.Name}</h5>
                    <span className="text-gray-600 text-sm font-medium">Created:</span><span>{' ' + convertDateStringToFriendlyDate(getDateTimeStringLocal(project.CreateDate))}</span>
                </div>

                <Menu as="div" className="relative inline-block text-left">
                    <div>
                        <Menu.Button className="text-gray-700 text-sm border rounded-lg px-3 py-2 duration-150 hover:bg-gray-100">
                            <Cog6ToothIcon className='h-6 w-6 text-gray-500' />
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95">
                        <Menu.Items className="absolute right-0 mt-2 w-44 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none border-1">
                            <div className="flex flex-col items-left p-2">
                                <Menu.Item>
                                    <button disabled={isNameUpdating} onClick={(e) => { if (!isNameUpdating) { openDialog(e) } }} className={`${isNameUpdating ? 'text-gray-300' : 'hover:bg-indigo-500 hover:text-white'} rounded-md py-1 font-medium text-left pl-2`}>
                                        Rename
                                    </button>
                                </Menu.Item>
                                <Menu.Item>
                                    <button onClick={(e) => { copyProject(e) }} className='hover:bg-indigo-500 rounded-md py-1 hover:text-white font-medium text-left pl-2'>
                                        Make a Copy
                                    </button>
                                </Menu.Item>
                                <Menu.Item>
                                    <button onClick={(e) => { deleteProject(e) }} className='hover:bg-indigo-500 rounded-md py-1 hover:text-white font-medium text-left pl-2'>
                                        Remove
                                    </button>
                                </Menu.Item>
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
            <div className="py-2 px-4 border-t text-right">
                <button onClick={(e) => { openProject(e, project) }} className={buttonClasses}>
                    Open
                </button>
            </div>
            <RenameDialog isDialogOpen={isDialogOpen} renameFunc={handleTitleSave} currentName={project.Name} closeDialog={closeDialog} />
        </div>
    )
}



export default DashboardProjectCard