import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import routes from "./constants/Routes";

import Home from "./components/home";
import About from "./components/about";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Header from "./components/header";
import Footer from "./components/footer/Footer";
import Dashboard from "./components/dashboard";
import DashboardHome from "./components/dashboard/dashboard-home";
import DashboardProjects from "./components/dashboard/dashboard-projects";
import DashboardRecentProjects from "./components/dashboard/dashboard-recent-projects";
import ForgetPassword from "./components/auth/ForgetPassword";
import PrivateRoute from "./components/private-route/PrivateRoute";
import LegalTerms from "./components/legal-terms";
import Profile from "./components/profile";
import Project from "./components/project";
import EmailNotVerified from "./components/email-not-verified";
import FaqList from "./components/faq";

import { AuthProvider } from "./contexts/AuthContext";
import { AppProvider } from "./contexts/AppContext";

function App() {
  return (
    <div className="flex flex-col h-screen">
      <Router>
        <AuthProvider>
          <AppProvider>
            <Header></Header>
            <div className="pt-16 grow">
              <Routes>
                <Route path={routes.ANY} element={<Home />}></Route>
                <Route path={routes.HOME} element={<Home />}></Route>
                <Route path={routes.ABOUT} element={<About />}></Route>
                <Route path={routes.SIGN_IN} element={<Login />}></Route>
                <Route path={routes.REGISTER} element={<Register />}></Route>
                <Route
                  path={routes.FORGET_PASSWORD}
                  element={<ForgetPassword />}
                ></Route>
                <Route
                  path={routes.LEGAL_TERMS}
                  element={<LegalTerms />}
                ></Route>
                <Route path={routes.FAQ} element={<FaqList />}></Route>
                <Route
                  exact
                  path={routes.DASHBOARD}
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path={routes.DASHBOARD_HOME}
                    element={<DashboardHome />}
                  ></Route>
                  <Route
                    path={routes.DASHBOARD_PROJECTS}
                    element={<DashboardProjects />}
                  ></Route>
                  <Route
                    path={routes.DASHBOARD_RECENT_PROJECTS}
                    element={<DashboardRecentProjects />}
                  ></Route>
                </Route>
                <Route
                  exact
                  path={routes.PROFILE}
                  element={
                    <PrivateRoute>
                      <Profile />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  exact
                  path={routes.PROJECT + "/:projectId"}
                  element={
                    <PrivateRoute>
                      <Project />
                    </PrivateRoute>
                  }
                ></Route>
                <Route
                  exact
                  path={routes.EMAIL_NOT_VERIFIED}
                  element={
                    <PrivateRoute>
                      <EmailNotVerified />
                    </PrivateRoute>
                  }
                ></Route>
              </Routes>
            </div>
            <Footer></Footer>
          </AppProvider>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
