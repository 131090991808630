import React, { useState } from 'react'
import { Navigate, Link } from 'react-router-dom'
import { auth } from '../../firebase'
import { addNewUser } from '../../firebase/firestore'
import { useAuth } from '../../contexts/AuthContext'
import { validateEmail, validatePassword } from '../../helper/helper'
import { ErrorMessageMap } from '../../constants/ErrorMessageMap'
import routes from '../../constants/Routes'
import CustomAlert from './../alert/index'

const Register = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setconfirmPassword] = useState('')
    const [isRegistering, setIsRegistering] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    const { userLoggedIn } = useAuth()

    const onSubmit = (e) => {
        e.preventDefault()

        if (!isRegistering) {
            // validate form values
            if (!validateEmail(email)) {
                setErrorMessage('Please enter a valid email.');
                return;
            }

            if (!validatePassword(password)) {
                setErrorMessage('Please enter a valid password. It should be at least 8 characters long and should contain a number and a special character.');
                return;
            }

            if (confirmPassword !== password) {
                setErrorMessage('Password and Confirm Password Do Not Match.');
                return;
            }

            // If form values are valid, reset error message and proceed with login
            setErrorMessage('');

            setIsRegistering(true)
            auth.doCreateUserWithEmailAndPassword(email, password)
                .then((authUser) => {
                    setIsRegistering(false)
                    addNewUser(authUser.user)
                    auth.doSendEmailVerification(authUser.user)
                })
                .catch(err => {
                    setIsRegistering(false)
                    if (ErrorMessageMap[err.code]) {
                        setErrorMessage(ErrorMessageMap[err.code]);
                    }
                    else {
                        setErrorMessage(ErrorMessageMap['default']);
                    }

                })
        }

    }

    return (
        <>
            {userLoggedIn && (<Navigate to={routes.DASHBOARD} replace={true} />)}
            <main className="w-full h-full flex flex-col items-center justify-center px-4">
                <div className="max-w-sm w-full text-gray-600 space-y-5">
                    <div className="text-center pb-8">
                        <div className="mt-2">
                            <h3 className="text-gray-800 text-2xl font-bold sm:text-3xl">Create a New Account</h3>
                        </div>
                        <div className="font-medium">
                        Already have an account? {'   '}
                        <Link to={routes.SIGN_IN} className="text-center text-indigo-600 hover:text-indigo-500">Sign In</Link>
                    </div>
                    </div>
                    <form
                        onSubmit={onSubmit}
                        className="space-y-5"
                    >
                        <div>
                            <label className="font-medium">
                                Email
                            </label>
                            <input
                                type="email"
                                autoComplete='email'
                                required
                                value={email} onChange={(e) => { setEmail(e.target.value) }}
                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                            />
                        </div>

                        <div>
                            <label className="font-medium">
                                Password
                            </label>
                            <input
                                type="password"
                                autoComplete='new-password'
                                required
                                value={password} onChange={(e) => { setPassword(e.target.value) }}
                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                            />
                        </div>

                        <div>
                            <label className="font-medium">
                                Confirm Password
                            </label>
                            <input
                                type="password"
                                autoComplete='off'
                                required
                                value={confirmPassword} onChange={(e) => { setconfirmPassword(e.target.value) }}
                                className="w-full mt-2 px-3 py-2 text-gray-500 bg-transparent outline-none border focus:border-indigo-600 shadow-sm rounded-lg"
                            />
                        </div>

                        {errorMessage && (
                            <CustomAlert type="danger" heading={'Error'} subheading={errorMessage}></CustomAlert>
                        )}

                        <button
                            type="submit"
                            disabled={isRegistering}
                            className={`w-full px-4 py-2 text-white font-medium rounded-lg ${isRegistering ? 'bg-indigo-300 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-600 duration-150'}`}
                        >
                            {isRegistering ? 'Signing Up...' : 'Sign Up'}
                        </button>

                    </form>

                    <div className="text-sm">
                        By signing up, you are agreeing to our {' '}
                        <Link to={routes.LEGAL_TERMS} className="text-center text-indigo-600 hover:text-indigo-500">Terms of Service and Privacy Policy</Link>
                    </div>
                </div>
            </main>
        </>
    )
}

export default Register