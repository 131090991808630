import React, { useEffect, useState } from 'react'
import { useApp } from '../../../contexts/AppContext';
import { useAuth } from '../../../contexts/AuthContext';
import { deepCopyArray } from '../../../helper/helper';
import { uuidv4 } from '@firebase/util';
import { getAllUserProjects } from '../../../firebase/firestore';
import { Link } from 'react-router-dom';
import routes from '../../../constants/Routes';
import DashboardProjectCard from '../dashboard-project-card';

const DashboardRecentProjects = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [recentProjects, setRecentProjects] = useState([])
  const { currentUser } = useAuth()
  const { projects, setProjects, projectsDirty, setProjectsDirty } = useApp()

  useEffect(() => {
    setIsLoading(true)
    getProjects(projects)
  }, [projectsDirty])

  useEffect(() => {
    getRecentProjects(projects)
  }, [projects])

  useEffect(() => {
    setIsLoading(false)
  }, [recentProjects])

  const getRecentProjects = (projects) => {
    const tempArr = deepCopyArray(projects)

    setRecentProjects(tempArr.sort((a, b) => {

      // Convert the datetime strings to Date objects
      const dateA = new Date(a.LastModified);
      const dateB = new Date(b.LastModified);

      // Compare the dates and return a negative value if dateA is before dateB,
      // a positive value if dateA is after dateB, and 0 if the dates are equal
      return dateB - dateA;
    }))
  }

  const getProjects = async () => {
    if (projectsDirty) {
      const data = await getAllUserProjects(currentUser)
      setProjects(data)
      setProjectsDirty(false)
    }
  }

  return (
    <>
      {isLoading ?
        <>
          {/* <div style={{ display: 'flex', gap: '15px', justifyContent: 'flex-start' }}>
            <CardSkeleton style={{ marginRight: '5px', width: '200px' }} />
            <CardSkeleton style={{ width: '200px' }} />
          </div> */}
        </>
        :
        <section className="px-4 py-4 sm:px-8">
          <h1 className="text-gray-800 font-bold text-2xl md:text-3xl">Your Recent Avatars</h1>
          {projects.length === 0
            ?
            <h2 className="text-gray-800 font-bold text-xl md:text-2xl">
              Don't have any recent avatars? <Link to={routes.DASHBOARD}>Create a new avatar</Link>
            </h2>
            : null}
          <div className="grid gap-x-8 gap-y-8 sm:grid-cols-2 lg:grid-cols-3">
            {recentProjects.map((p) => {
              return <DashboardProjectCard key={uuidv4()} project={p} setProjectsDirty={setProjectsDirty}></DashboardProjectCard>
            })}
          </div>
        </section>
      }
    </>
  )
}

export default DashboardRecentProjects