import React, { useContext, useState, useEffect } from "react";
import { getAllProjectTypes } from "../firebase/firestore";
import { getFileUrl } from "../firebase/storage";

import { useAuth } from "./AuthContext";

const AppContext = React.createContext();

export function useApp() {
  return useContext(AppContext);
}

export function AppProvider({ children }) {
  const { userLoggedIn } = useAuth();

  const [projectTypes, setProjectTypes] = useState([]);
  const [projectTypesLoaded, setProjectTypesLoaded] = useState(false);
  const [projects, setProjects] = useState([]);
  const [projectsDirty, setProjectsDirty] = useState(true);
  const [avatarUpdating, setAvatarUpdating] = useState(false);
  const [currentProjectInfo, setCurrentProjectInfo] = useState({});

  useEffect(() => {
    if (userLoggedIn && !projectTypesLoaded) {
      const getProjectTypes = async () => {
        const data = await getAllProjectTypes();
        const promises = [];

        data.forEach((d) => {

          // -------------------------------------------------------------------------------
          if (d.Characters.Available) {
            Object.keys(d.Characters.Types).forEach(async (type) => {
              promises.push(
                getFileUrl(
                  `avatars/${d.Type}/thumbnails/character/${type}.png`
                ).then((url) => {
                  d.Characters.Types[type].ThumbnailUrl = url;
                })
              );
            });
          }
          // -------------------------------------------------------------------------------

          // -------------------------------------------------------------------------------
          if (d.Skin.Available) {
            Object.keys(d.Skin.Types).forEach(async (type) => {
              promises.push(
                getFileUrl(
                  `avatars/${d.Type}/thumbnails/skin/${type}.png`
                ).then((url) => {
                  d.Skin.Types[type].ThumbnailUrl = url;
                })
              );
            });
          }
          // -------------------------------------------------------------------------------

          // -------------------------------------------------------------------------------
          if (d.Pose.Available) {
            Object.keys(d.Pose.Types).forEach(async (type) => {
              promises.push(
                getFileUrl(
                  `avatars/${d.Type}/thumbnails/pose/${type}.png`
                ).then((url) => {
                  d.Pose.Types[type].ThumbnailUrl = url;
                })
              );
            });
          }
          // -------------------------------------------------------------------------------

          // -------------------------------------------------------------------------------
          if (d.Expression.Available) {
            Object.keys(d.Expression.Types).forEach(async (type) => {
              promises.push(
                getFileUrl(
                  `avatars/${d.Type}/thumbnails/expression/${type}.png`
                ).then((url) => {
                  d.Expression.Types[type].ThumbnailUrl = url;
                })
              );
            });
          }
          // -------------------------------------------------------------------------------

          // -------------------------------------------------------------------------------
          if (d.Clothing.Available) {
            Object.keys(d.Clothing.Types).forEach(async (type) => {
              promises.push(
                getFileUrl(
                  `avatars/${d.Type}/thumbnails/clothing/${type}.png`
                ).then((url) => {
                  d.Clothing.Types[type].ThumbnailUrl = url;
                })
              );
            });
          }
          // -------------------------------------------------------------------------------

          // -------------------------------------------------------------------------------
          if (d.Hair.Available) {
            Object.keys(d.Hair.Types).forEach(async (type) => {
              promises.push(
                getFileUrl(
                  `avatars/${d.Type}/thumbnails/hair/${type}.png`
                ).then((url) => {
                  d.Hair.Types[type].ThumbnailUrl = url;
                })
              );
            });
          }
          // -------------------------------------------------------------------------------

          // -------------------------------------------------------------------------------
          if (d.Beard.Available) {
            Object.keys(d.Beard.Types).forEach(async (type) => {
              promises.push(
                getFileUrl(
                  `avatars/${d.Type}/thumbnails/beard/${type}.png`
                ).then((url) => {
                  d.Beard.Types[type].ThumbnailUrl = url;
                })
              );
            });
          }
          // -------------------------------------------------------------------------------

          // -------------------------------------------------------------------------------
          if (d.Lips.Available) {
            Object.keys(d.Lips.Types).forEach(async (type) => {
              promises.push(
                getFileUrl(
                  `avatars/${d.Type}/thumbnails/lips/${type}.png`
                ).then((url) => {
                  d.Lips.Types[type].ThumbnailUrl = url;
                })
              );
            });
          }
          // -------------------------------------------------------------------------------

          // -------------------------------------------------------------------------------
          if (d.Eyes.Available) {
            Object.keys(d.Eyes.Types).forEach(async (type) => {
              promises.push(
                getFileUrl(
                  `avatars/${d.Type}/thumbnails/eyes/${type}.png`
                ).then((url) => {
                  d.Eyes.Types[type].ThumbnailUrl = url;
                })
              );
            });
          }
          // -------------------------------------------------------------------------------

          // -------------------------------------------------------------------------------
          if (d.Accessory_Eyewear.Available) {
            Object.keys(d.Accessory_Eyewear.Types).forEach(async (type) => {
              promises.push(
                getFileUrl(
                  `avatars/${d.Type}/thumbnails/accessory_eyewear/${type}.png`
                ).then((url) => {
                  d.Accessory_Eyewear.Types[type].ThumbnailUrl = url;
                })
              );
            });
          }
          // -------------------------------------------------------------------------------

          // // -------------------------------------------------------------------------------
          // const makeupTypesWithThumbs = d.MakeupTypes.map(async (type) => {
          //   const thumnb = await getFileUrl(
          //     `avatars/${d.Type}/thumbnails/makeup/${type.PreviewImage}`
          //   );
          //   return { ...type, thumbnail: thumnb };
          // });

          // const updatedMakeupTypes = await Promise.all(makeupTypesWithThumbs);
          // d.MakeupTypes = updatedMakeupTypes;
          // // -------------------------------------------------------------------------------

          // -------------------------------------------------------------------------------
          // Backgrounds
          Object.keys(d.Backgrounds.Types).forEach(async (type) => {
            promises.push(
              getFileUrl(
                `avatars/${d.Type}/thumbnails/backgrounds/${d.Backgrounds.Types[type].Id}.jpg`
              ).then((url) => {
                d.Backgrounds.Types[type].ThumbnailUrl = url;
              })
            );
          });
          // -------------------------------------------------------------------------------

          promises.push(
            getFileUrl(`avatars/${d.Type}/thumbnails/avatar/avatar.png`).then(
              (url) => {
                d.ThumbnailUrl = url;
              }
            )
          );

          // const thumnb = await getFileUrl(
          //   `avatars/${d.Type}/thumbnails/avatar/avatar.png`
          // );
          // return { ...d, thumbnail: thumnb };
        });

        Promise.all(promises).then((results) => {
          setProjectTypes(data);
          setProjectTypesLoaded(true);
        });
      };

      getProjectTypes();
    }
  }, [userLoggedIn]);

  const value = {
    projectTypes,
    setProjectTypes,

    projects,
    setProjects,
    projectsDirty,
    setProjectsDirty,

    avatarUpdating,
    setAvatarUpdating,

    currentProjectInfo,
    setCurrentProjectInfo,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}
