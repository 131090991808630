import React, { useState } from 'react'

const UserCard = ({ displayName, email, onSubmit, isUpdating }) => {
    const [formDisplayName, setFormDisplayName] = useState(displayName);

    const handleSubmit = event => {
        event.preventDefault();
        onSubmit({
            displayName: formDisplayName
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label className="block py-3 text-gray-500">
                    Display Name
                </label>
                <div className="md:w-1/2 flex items-center p-2 border rounded-md">
                    <input
                        value={formDisplayName ? formDisplayName : ''}
                        onChange={event => setFormDisplayName(event.target.value)}
                        type="text"
                        className="w-full p-1 ml-3 text-gray-500 outline-none bg-transparent"
                    />
                </div>
            </div>

            <div>
                <label className="block py-3 text-gray-500">
                    Email
                </label>
                <div className="md:w-1/2 flex items-center p-2 border rounded-md">
                    <input
                        readOnly
                        value={email}
                        type="text"
                        className="w-full p-1 ml-3 text-gray-500 outline-none bg-transparent"
                    />
                </div>
            </div>
            <button
                type="submit"
                disabled={isUpdating}
                className={`${isUpdating ? 'bg-indigo-300 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700'} my-3 px-4 py-2 text-white font-medium rounded-lg duration-150 active:shadow-lg`}>
                {isUpdating ? 'Saving...' : 'Save'}
            </button>
        </form>
    );
};

export default UserCard;