import React, { Fragment } from 'react'

const PrivacyPolicy = () => {
  return (
    <Fragment>
      <div className='py-2'>
        <h3 className='text-lg font-medium'>Types of Information Collected</h3>
        <p>We collect the following information from users who create an account on our website:</p>
        <ul className='list-disc ml-8 pb-2 text-md'>
          <li>Email address</li>
          <li>Password (encrypted)</li>
          <li>User name (in case of third-party authentication providers)</li>
        </ul>
      </div>

      <div className='py-2'>
        <h3 className='text-lg font-medium'>Information Collection Methods</h3>
        <p>We collect information from users through the following means:</p>
        <ul className='list-disc ml-8 pb-2 text-md'>
          <li>Website forms</li>
          <li>Cookies</li>
          <li>Browser storage</li>
        </ul>
      </div>

      <div className='py-2'>
        <h3 className='text-lg font-medium'>Purpose of Collecting Information</h3>
        <p>The information collected from users is used for the following purposes:</p>
        <ul className='list-disc ml-8 pb-2 text-md'>
          <li>To identify users</li>
          <li>For billing purposes</li>
          <li>To improve the user experience on our website</li>
        </ul>
      </div>

      <div className='py-2'>
        <h3 className='text-lg font-medium'>Use of Information</h3>
        <p>We use the personal information collected from users for the following purposes:</p>
        <ul className='list-disc ml-8 pb-2 text-md'>
          <li>To fulfill orders</li>
          <li>To send email notifications or updates related to the website</li>
          <li>To improve the website and provide a better user experience</li>
        </ul>
      </div>

      <div className='py-2'>
        <h3 className='text-lg font-medium'>Sharing of Information</h3>
        <p>We do not share any personal information collected from users with third parties, except as required by law or in accordance with the user's consent.</p>
      </div>

      <div className='py-2'>
        <h3 className='text-lg font-medium'>Data Security</h3>
        <p>We take the security of user information very seriously and have implemented appropriate measures to protect it. We use encryption, firewalls, and secure servers to secure user information.</p>
      </div>

      <div className='py-2'>
        <h3 className='text-lg font-medium'>Data Retention</h3>
        <p>We retain user information for as long as the user's account is active. Upon the user's request, we will delete their account and all associated personal information within 15 days.</p>
      </div>

      <div className='py-2'>
        <h3 className='text-lg font-medium'>User Rights</h3>
        <p>Users have the right to access and view their saved personal information at any time. They also have the right to request that their account and associated personal information be deleted at any time.</p>
      </div>

      <div className='py-2'>
        <h3 className='text-lg font-medium'>Changes to the Privacy Policy</h3>
        <p>We may update this Privacy Policy from time to time. If we make changes, we will notify users via email and request their acceptance the next time they login to the website. By using our website, you agree to the terms of this Privacy Policy.</p>
      </div>

      <div className='py-2'>
        <h3 className='text-lg font-medium'>GDPR Compliance</h3>
        <p>We are committed to complying with the General Data Protection Regulation (GDPR) and protecting the privacy of our users. We collect onlythe necessary personal information, such as name and email address, for the purposes outlined in this Privacy Policy. Upon request, we will delete a user's account and all associated personal information within 15 days.</p>
      </div>

      <div className='py-2'>
        <p>If you have any questions or concerns about our Privacy Policy or the information we collect and use, please feel free to contact us at support@avatarsmith.com.</p>
      </div>

    </Fragment>

  )
}

export default PrivacyPolicy