import { getCurrentDateTime } from "../helper/helper";

export const getNewProject = (project) => {
  const currentDateTime = getCurrentDateTime();

  const newProject = {
    AvatarType: project.Type,
    Name: project.Name,
    CreateDate: currentDateTime,
    LastModified: currentDateTime,

    Background: {
      HasBackground: false,
      Type: "",
      Selection: "",
    },

    OverlayText: {},

    Character: project.Defaults.Character,
    Pose: project.Defaults.Pose,
    Skin: project.Defaults.Skin,    
    Eyes: project.Defaults.Eyes,
    Expression: project.Defaults.Expression,    
    Lips: {
      Enabled: project.Defaults.Lips.length > 0,
      Selection: project.Defaults.Lips,
    },
    Makeup: {
      Enabled: project.Defaults.Makeup.length > 0,
      Selection: project.Defaults.Makeup,
    },
    Clothing: {
      Enabled: project.Defaults.Clothing.length > 0,
      Selection: project.Defaults.Clothing,
    },
    Accessory_Eyewear: {
      Enabled: project.Defaults.Accessory_Eyewear.length > 0,
      Selection: project.Defaults.Accessory_Eyewear,
    },
    Accessory_Earring: {
      Enabled: project.Defaults.Accessory_Earring.length > 0,
      Selection: project.Defaults.Accessory_Earring,
    },
    Accessory_Necklace: {
      Enabled: project.Defaults.Accessory_Necklace.length > 0,
      Selection: project.Defaults.Accessory_Necklace,
    },
    Hair: {
      Enabled: project.Defaults.Hair.length > 0,
      Selection: project.Defaults.Hair,
    },
    Beard: {
      Enabled: project.Defaults.Beard.length > 0,
      Selection: project.Defaults.Beard,
    }
  };

  return newProject;
};
