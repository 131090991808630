import React, { useState, Fragment } from 'react'
import { useAuth } from "../../contexts/AuthContext"
import { auth } from '../../firebase'
import { useApp } from '../../contexts/AppContext'
import Routes from '../../constants/Routes'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'

const Header = () => {
  const { userLoggedIn, currentUser } = useAuth()
  const { setProjects, setProjectsDirty } = useApp()
  const navigate = useNavigate()

  const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false)

  const loginClick = (e) => {
    e.preventDefault()
    setHamburgerMenuOpen(false)
    navigate(Routes.SIGN_IN)
  }

  const registerClick = (e) => {
    e.preventDefault()
    setHamburgerMenuOpen(false)
    navigate(Routes.REGISTER)
  }

  const logoutClick = (e) => {
    e.preventDefault()

    auth.doSignOut()
      .then(() => {
        setProjectsDirty(true)
        setProjects([])
        setHamburgerMenuOpen(false)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const profileClick = (e) => {
    e.preventDefault()
    setHamburgerMenuOpen(false)
    navigate(Routes.PROFILE)
  }

  const dashboardClick = (e) => {
    e.preventDefault()
    setHamburgerMenuOpen(false)
    navigate(Routes.DASHBOARD)
  }

  const buttonClasses = 'w-full px-4 py-2 text-white font-medium rounded-lg bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-600 duration-150'

  return (
    <nav className="bg-gray-800 w-full top-0 z-20 fixed top-0 left-0 h-16">
      <div className="bg-gray-800 items-center px-4 max-w-screen-xl mx-auto md:px-8 lg:flex">
        <div className="flex items-center justify-between py-3 lg:py-4 lg:block">
          <Link className='text-white text-2xl no-underline' to={'/'}>
            <span className='flex flex-row align-middle'><img src={process.env.PUBLIC_URL + '/images/logo.png'} alt={'Avatar Smith Header Logo'} className='w-8 h-8'></img> <span className='flex items-center'>Avatar Smith</span></span></Link>
          <div className="lg:hidden">
            <button className="text-gray-700 outline-none rounded-md focus:border-gray-400 focus:border"
              onClick={() => setHamburgerMenuOpen(!hamburgerMenuOpen)}
            >
              {
                hamburgerMenuOpen ? (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" viewBox="0 0 20 20" fill="white">
                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                  </svg>
                ) : (
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 8h16M4 16h16" />
                  </svg>
                )
              }
            </button>
          </div>
        </div>
        <div className={`flex-1 justify-between flex-row-reverse lg:overflow-visible lg:flex lg:pb-0 lg:pr-0 lg:h-auto ${hamburgerMenuOpen ? 'overflow-auto pb-10 pr-4' : 'hidden'}`}>
          <div>
            <ul className="flex m-0 flex-col space-x-0 lg:space-x-2 lg:flex-row">
              {!userLoggedIn ?
                <Fragment>
                  <li className="my-2 lg:mt-0 lg:mb-0">
                    <button onClick={(e) => { loginClick(e) }} className={buttonClasses}>
                      Login
                    </button>
                  </li>

                  <li className="my-2 lg:mt-0 lg:mb-0">
                    <button onClick={(e) => { registerClick(e) }} className={buttonClasses}>
                      Register
                    </button>
                  </li>
                </Fragment>
                :
                <Fragment>
                  {
                    currentUser.emailVerified &&
                    <Fragment>
                      <li className="my-2 lg:mt-0 lg:mb-0">
                        <button onClick={(e) => { dashboardClick(e) }} className={buttonClasses}>
                          Dashboard
                        </button>
                      </li>
                      <li className="my-2 lg:mt-0 lg:mb-0">
                        <button onClick={(e) => { profileClick(e) }} className={buttonClasses}>
                          Profile
                        </button>
                      </li>
                    </Fragment>
                  }

                  <li className="my-2 lg:mt-0 lg:mb-0">
                    <button onClick={(e) => { logoutClick(e) }} className={buttonClasses}>
                      Log Out
                    </button>
                  </li>
                </Fragment>
              }
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Header