import React, {memo} from 'react'
const EyesCardBody = ({ handleChange, eyesList, selectedItem, avatarUpdating }) => {
    return (
        <div className='flex flex-col gap-y-3' >
            {Object.keys(eyesList).sort().map(type =>
                <div key={type} className='border-[1px] rounded-xl'>
                    <img className="mx-auto d-block w-32 h-32"
                        src={eyesList[type].ThumbnailUrl}
                        alt={eyesList[type].Caption + ' Image'} />
                    <div className='mx-1'>
                        <button
                            className={`${avatarUpdating || (type === selectedItem)
                                ? 'bg-indigo-300 cursor-not-allowed'
                                : 'bg-indigo-600 hover:bg-indigo-500 active:bg-indigo-600'} w-full my-2 py-1 text-sm rounded-md`}
                            disabled={avatarUpdating || (type === selectedItem)}
                            onClick={(e) => { handleChange(e, type) }}>
                            {(type === selectedItem) ? 'Applied' : 'Apply'}
                        </button>
                    </div>
                </div>)}
        </div>

    )
}

export default memo(EyesCardBody)