import React, { useState, Fragment, memo } from 'react'

const Sidebar = ({ mainList, floatingList, floatingListVisible }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleToggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Fragment>
            <div className={`${isOpen ? '!block' : 'hidden'} lg:block bg-gray-800 pt-1 h-full text-white w-32 fixed left-0 hidden top-16`}>
                {mainList}
            </div>
            {
                floatingListVisible &&
                <div className={`${isOpen ? '!block' : 'hidden'} bg-white overflow-y-auto p-4 lg:block border-r-2 border-gray-800 pt-1 !pb-[100px] h-full text-white w-48 fixed left-32 hidden top-16`}>
                    <div className='w-full'>{floatingList}</div>
                </div>
            }

            <div className={`${isOpen ? floatingListVisible ? 'left-80': 'left-32' : 'left-0'} lg:hidden bg-gray-800 h-auto w-auto text-white fixed mt-4 rounded-r-lg`}>
                <button className="px-3 py-3 mx-auto" onClick={handleToggleSidebar}>
                    <svg className="h-6 w-6 text-white" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4 6H20M4 12H20M4 18H20" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
            </div>
        </Fragment>
    )
}

export default memo(Sidebar)