import React from 'react'
import { useAuth } from '../../contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import routes from '../../constants/Routes';

const EmailNotVerified = () => {
    const { userLoggedIn, currentUser } = useAuth()
    return (
        <>
            {userLoggedIn && currentUser.emailVerified && (<Navigate to={routes.DASHBOARD} replace={true} />)}
            <main>
                <div className="max-w-screen-xl mx-auto px-4 flex items-center justify-start h-screen md:px-8">
                    <div className="max-w-lg mx-auto space-y-3 text-center">
                        <h3 className="text-gray-800 text-4xl font-semibold sm:text-5xl">
                            Email not verified
                        </h3>
                        <p className="text-gray-600">
                            Sorry, the email id associated with your account is not verified. To use this website, your email id needs to be verified.
                        </p>
                        <p className="text-gray-600">
                            An email with the verification link has already been sent to your email.
                        </p>
                        <p className="text-gray-600">
                            Please check your inbox and spam folders. If you have already verified your email by clicking on the link in your email, then refresh this page.
                        </p>
                    </div>
                </div>
            </main>
        </>
    )
}

export default EmailNotVerified