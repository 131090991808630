const routes = {
    REGISTER : "/register",
    SIGN_IN : "/signin",
    
    DASHBOARD : "/dashboard",
    DASHBOARD_HOME: '',
    DASHBOARD_PROJECTS: 'projects',
    DASHBOARD_RECENT_PROJECTS: 'recent-projects',

    HOME : "/",
    ABOUT : "/about",
    ACCOUNT : "/account",
    FORGET_PASSWORD : "/forget-password",
    LEGAL_TERMS : "/legal-terms",
    FAQ : "/faq",
    PROFILE : "/profile",
    PROJECT : "/project",
    EMAIL_NOT_VERIFIED : "/email-not-verified",

    ANY : "*",
}

export default routes